import React, { useState, useEffect } from 'react'
import {
    Form,
    Label,
    Input,
    Header,
    Radio,
    FormInput,
    Divider,
    Popup,
    Icon,
} from 'semantic-ui-react'
import { Modal } from 'react-bootstrap'
import { toCredit } from '../../../utils/helpers'

const calculateMarginBasedCredits = (paymentAmount, margin, perCreditPrice) =>
    Math.ceil((paymentAmount / (1 - margin / 100) / perCreditPrice) * 100) / 100

const calculateMarginAmount = (paymentAmount, margin) => {
    const marginAmount = paymentAmount / (1 - margin / 100) - paymentAmount
    return parseFloat(marginAmount.toFixed(2))
}

const calculateHonorariumBasedCredits = (paymentAmount) => {
    return paymentAmount <= 350
        ? 1
        : paymentAmount <= 525
        ? 1.5
        : paymentAmount <= 700
        ? 2
        : 2.5
}

const Credit = ({ job, payment, updatePayment, meetingData }) => {
    const expertRate = toCredit(job.user.expert.hourlyRate)
    const [margin, setMargin] = useState(50)

    const { amount, description } = payment
    const perCreditPrice = job.project.team.perCreditPrice || 700
    const CREDIT_DEDUCTION_METHODS = {
        HONORARIUM_BASED: {
            value: 'Credit threshold based on total honorarium',
            target: 'Decibio',
        },
        EXPERT_RATE_BASED: {
            value: 'Credit threshold based on expert rate',
            target: 'Non-Decibio',
        },
        MARGIN_BASED: {
            value: 'Partial credit based on a margin',
            target: 'Decibio',
        },
    }
    let defaultCreditDeductionMethod
    let defaultCredit
    let recommendation
    const team = job.project.team.name
    const projectType = job.project.type

    if (team === 'Decibio') {
        if (projectType === 'Survey' || meetingData.callMinutes <= 30) {
            defaultCreditDeductionMethod =
                CREDIT_DEDUCTION_METHODS['MARGIN_BASED'].value
            defaultCredit = calculateMarginBasedCredits(
                amount,
                margin,
                perCreditPrice
            )
            recommendation =
                projectType === 'Survey'
                    ? 'Best pick: DBC survey'
                    : 'Best pick: DBC ≤30min interview'
        } else if (
            projectType === 'Interview' &&
            meetingData.callMinutes > 30
        ) {
            defaultCreditDeductionMethod =
                CREDIT_DEDUCTION_METHODS['HONORARIUM_BASED'].value
            defaultCredit = calculateHonorariumBasedCredits(amount)
            recommendation = 'Best pick: DBC >30 min interview'
        }
    } else {
        defaultCreditDeductionMethod =
            CREDIT_DEDUCTION_METHODS['EXPERT_RATE_BASED'].value
        defaultCredit =
            projectType === 'Survey'
                ? 0.25
                : meetingData.callMinutes <= 10
                ? 0
                : toCredit(job.user.expert.hourlyRate)
        recommendation =
            projectType === 'Survey'
                ? 'Standard survey'
                : meetingData.callMinutes <= 10
                ? '≤10 min interview grace'
                : 'Standard interview'
    }
    const [creditDeductionMethod, setCreditDeductionMethod] = useState(
        defaultCreditDeductionMethod
    )

    const [credits, setCredits] = useState(defaultCredit)
    const [dollarTotal, setDollarTotal] = useState(
        (calculateMarginAmount(amount, margin) + amount).toFixed(2)
    )

    const updateCredits = (credits) => {
        setCredits(credits)
        updatePayment({ credits })
    }

    useEffect(() => {
        updatePayment({ credits: defaultCredit })
    }, [defaultCredit, updatePayment])

    const resetCredits = (method, margin, perCreditPrice) => {
        let newCredits
        if (method === CREDIT_DEDUCTION_METHODS['MARGIN_BASED'].value) {
            newCredits = calculateMarginBasedCredits(
                amount,
                margin,
                perCreditPrice
            )
        } else if (
            method === CREDIT_DEDUCTION_METHODS['HONORARIUM_BASED'].value
        ) {
            newCredits = calculateHonorariumBasedCredits(amount)
        } else newCredits = expertRate

        updateCredits(newCredits)
    }
    const resetMargin = (credits, amount, perCreditPrice) => {
        const totalPayment = parseFloat((credits * perCreditPrice).toFixed(2))
        const marginAmount = totalPayment - amount
        const marginPercentage = ((marginAmount / totalPayment) * 100).toFixed(
            3
        )
        setMargin(parseFloat(marginPercentage))
    }

    const margin_based_credits = (
        <div className="top-bottom-margin">
            <Header as="h6">
                {'Credit amount   '}
                <span>
                    <Popup
                        trigger={
                            <Icon
                                name="info"
                                inverted
                                circular
                                size="tiny"
                                color="grey"
                            />
                        }
                        style={{
                            zIndex: 9999,
                        }}
                        content="Use the below tool to determine credit to be deducted. The per credit price is derived from the team's most recent purchase history"
                    />
                </span>
            </Header>
            <Form.Group>
                <div>
                    <span className="credit-calc-header">Total honorarium</span>
                </div>
                <FormInput
                    labelPosition="right"
                    type="number"
                    min="0"
                    placeholder="Amount"
                    value={amount}
                    disabled
                >
                    <Label basic>$</Label>
                    <input />
                    <Label>USD</Label>
                </FormInput>
                <div className="recommendation">{description}</div>
            </Form.Group>
            <Form.Group>
                <div className="top-margin-small">
                    <span className="credit-calc-header">Margin</span>
                </div>
                <Form.Input
                    labelPosition="right"
                    type="number"
                    style={{ width: '50%' }}
                    min="0"
                    placeholder="Margin"
                    value={margin}
                    onChange={(e, { value }) => {
                        setMargin(value)
                        resetCredits(
                            creditDeductionMethod,
                            value,
                            perCreditPrice
                        )
                        setDollarTotal(
                            (
                                calculateMarginAmount(amount, value) + amount
                            ).toFixed(2)
                        )
                    }}
                >
                    <input />
                    <Label basic>%</Label>
                </Form.Input>
                <span className="recommendation">
                    +${calculateMarginAmount(amount, margin)} margin
                </span>
            </Form.Group>
            <Form.Group>
                <div className="top-margin-small">
                    <span className="credit-calc-header">Per Credit Price</span>
                </div>
                <Form.Input
                    labelPosition="right"
                    type="number"
                    disabled
                    min="0"
                    placeholder="Per Credit Price"
                    value={perCreditPrice}
                >
                    <Label basic>$</Label>
                    <input />
                    <Label basic>Per Credit</Label>
                </Form.Input>
            </Form.Group>

            <Divider />
            <Form.Group>
                <Header as="h6">Total credit(s) to deduct</Header>
                <FormInput
                    labelPosition="right"
                    type="number"
                    min="0"
                    step="0.01"
                    placeholder="Amount"
                    value={credits}
                    onChange={(e, { value }) => {
                        updateCredits(parseFloat(value))
                        setDollarTotal((value * perCreditPrice).toFixed(2))
                        resetMargin(parseFloat(value), amount, perCreditPrice)
                    }}
                >
                    <input />
                    <Label basic>Credit(s)</Label>
                </FormInput>
                <span className="recommendation">
                    ${dollarTotal} converted to credits
                </span>
            </Form.Group>
        </div>
    )
    return (
        <Modal.Body>
            <Header as="h6" className="top-margin-small">
                Team: {team} <br /> <br />
                Credit deduction method
            </Header>
            {Object.keys(CREDIT_DEDUCTION_METHODS).map((method) => (
                <Form.Field key={method}>
                    <Radio
                        hidden={
                            (team === 'Decibio' &&
                                CREDIT_DEDUCTION_METHODS[method].target !==
                                    'Decibio') ||
                            (team !== 'Decibio' &&
                                CREDIT_DEDUCTION_METHODS[method].target ===
                                    'Decibio')
                        }
                        label={CREDIT_DEDUCTION_METHODS[method].value}
                        value={CREDIT_DEDUCTION_METHODS[method].value}
                        checked={
                            creditDeductionMethod ===
                            CREDIT_DEDUCTION_METHODS[method].value
                        }
                        onChange={(e, { value }) => {
                            setCreditDeductionMethod(value)
                            resetCredits(value, margin, perCreditPrice)
                        }}
                    />
                    {defaultCreditDeductionMethod ===
                        CREDIT_DEDUCTION_METHODS[method].value && (
                        <span className="recommendation left-margin">
                            {recommendation}
                        </span>
                    )}
                </Form.Field>
            ))}
            <div className="top-margin-two">
                {creditDeductionMethod !==
                    CREDIT_DEDUCTION_METHODS['MARGIN_BASED'].value && (
                    <div>
                        <Header as="h6">Total credit(s) to deduct</Header>
                        <Input
                            labelPosition="right"
                            type="number"
                            min="0"
                            placeholder="Amount"
                            value={credits}
                            step="0.1"
                            onChange={(e, { value }) => {
                                updateCredits(parseFloat(value))
                            }}
                        >
                            <input />
                            <Label>Credit(s)</Label>
                        </Input>
                    </div>
                )}
                {creditDeductionMethod ===
                    CREDIT_DEDUCTION_METHODS['MARGIN_BASED'].value &&
                    margin_based_credits}
            </div>
            {creditDeductionMethod ===
                CREDIT_DEDUCTION_METHODS['HONORARIUM_BASED'].value && (
                <div>
                    <div className="recommendation bottom-margin">
                        Based on {description}
                    </div>
                    <div>
                        Credit Threshold for DBC:
                        <br />
                        - ≤350 = 1 credit;
                        <br />
                        - $351-$525 = 1.5 credits;
                        <br />
                        - $526-$700 = 2 credits;
                        <br />- $701+ = 2.5 credits
                    </div>
                </div>
            )}
            {team !== 'Decibio' && (
                <div className="recommendation bottom-margin">
                    {projectType === 'Interview' &&
                        `Expert hourly rate: ${expertRate} credit(s) `}{' '}
                    <br />
                </div>
            )}
            {projectType === 'Interview' && team !== 'Decibio' && (
                <div>
                    Credit Threshold for non-DBC Clients:
                    <br />
                    - Interviews ≤10 min = 0 credits;
                    <br />
                    - ≤450 = 1 credit;
                    <br />
                    - $451-$750 = 1.5 credits;
                    <br />- $751+ = 2 credits;
                </div>
            )}
            {projectType === 'Survey' && team !== 'Decibio' && (
                <div>
                    <br />
                    Standard rate for surveys is 0.25 credit per response
                </div>
            )}
        </Modal.Body>
    )
}

export default Credit

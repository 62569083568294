import React, { useState, useEffect, useContext } from 'react'
import { Form, Header } from 'semantic-ui-react'
import { Editor } from 'react-draft-wysiwyg'
import {
    ContentState,
    EditorState,
    convertToRaw,
    convertFromHTML,
} from 'draft-js'
import { getHTMLFromEditorState } from '../../../../utils/helpers'
import { getAfterPaymentMessageMarkup } from '../../../Emails'
import { AppContext } from '../../../../Context'

const Message = ({ job, payment, updatePayment }) => {
    const { user } = useContext(AppContext)

    const [editorState, setEditorState] = useState(null)
    const [hasMessage, setHasMessage] = useState(false)

    useEffect(() => {
        if (hasMessage && !payment.additionalMessage) {
            const markup = getAfterPaymentMessageMarkup(
                job.user.expert.salutation,
                job.user.firstName,
                job.user.lastName,
                job.project.title,
                payment.amount,
                payment.description,
                user.fullName || user.email
            )
            const blocksFromHTML = convertFromHTML(markup)
            const editorState = EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                )
            )
            setEditorState(editorState)
            updatePayment({
                additionalMessage: getHTMLFromEditorState(
                    JSON.stringify(
                        convertToRaw(editorState.getCurrentContent())
                    )
                ),
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasMessage, job, user, payment.additionalMessage])
    return (
        <Form>
            <Form.Field>
                <Header as="h6">Message</Header>
                <Form.Checkbox
                    label={`Send a custom message to ${
                        job.user.fullName || job.user.email
                    }`}
                    checked={hasMessage}
                    onChange={(e, { checked }) => {
                        setHasMessage(checked)
                        updatePayment({ additionalMessage: null })
                    }}
                />
            </Form.Field>
            {hasMessage && (
                <Form.Field>
                    <label>
                        Write a custom message below. This will be sent as a
                        plain text email with no Dexter logo or text formatting
                        and with the subject line, &quot;Re: Your Dexter
                        engagement on {job.project.title}&quot;. The expert will
                        still receive an automated notification from Dexter.
                    </label>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={(editorState) => {
                            setEditorState(editorState)
                            updatePayment({
                                additionalMessage: getHTMLFromEditorState(
                                    JSON.stringify(
                                        convertToRaw(
                                            editorState.getCurrentContent()
                                        )
                                    )
                                ),
                            })
                        }}
                    />
                </Form.Field>
            )}
        </Form>
    )
}

export default Message

import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Dashboard from './Dashboard'
import ManageTeams from './Teams'
import TeamManage from './Teams/TeamManage'
import ManageProjects from './Projects'
import ManageProject from './Projects/ManageProject'
import ManageJobs from './Jobs'
import ManageJob from './Jobs/ManageJob'
import ManageOrganizations from './Organizations'
import ManageExperts from './Experts'

const AdminRoutes = () => {
    return (
        <Switch>
            <Route
                exact
                path="/admin"
                render={() => <Redirect to="/admin/teams" />}
            />
            <Route exact path="/admin/dashboard" component={Dashboard} />
            <Route exact path="/admin/teams" component={ManageTeams} />
            <Route exact path="/admin/teams/:id/:page" component={TeamManage} />
            <Route exact path="/admin/projects" component={ManageProjects} />
            <Route exact path="/admin/projects/:id" component={ManageProject} />
            <Route exact path="/admin/jobs" component={ManageJobs} />
            <Route exact path="/admin/jobs/:id" component={ManageJob} />
            <Route
                exact
                path="/admin/organizations"
                component={ManageOrganizations}
            />
            <Route exact path="/admin/experts" component={ManageExperts} />
            <Route render={() => <Redirect to="/not-found-page" />} />
        </Switch>
    )
}

export default AdminRoutes

/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react'
import { Button } from 'semantic-ui-react'
import CompleteModal from '../../../Modals/ProjectComplete/CompleteModal'

const CompleteButton = ({ advisor, project, job }) => {
    const [showCompleteModal, setShowCompleteModal] = useState(false)
    return (
        <Fragment>
            <CompleteModal
                onShow={showCompleteModal}
                onHide={() => setShowCompleteModal(false)}
                advisor={advisor}
                project={project}
                job={job}
            />
            <Button
                size="mini"
                floated="right"
                color="green"
                disabled={
                    project.status !== 'Active' ||
                    (project.team.name !== 'Decibio' && !job.zoomId)
                }
                onClick={() => setShowCompleteModal(true)}
            >
                Mark complete
            </Button>
        </Fragment>
    )
}

export default CompleteButton

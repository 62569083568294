import React, {
    Fragment,
    useState,
    useContext,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react'
import { useQuery } from '@apollo/client'
import { Form, Loader } from 'semantic-ui-react'
import { LOAD_TEAM_MEMBERS_QUERY } from '../../../graphql/team'
import { AppContext } from '../../../Context'

const Identity = (props, ref) => {
    const { currentTeam } = useContext(AppContext)
    const { project, error } = props

    const [projectTitle, setProjectTitle] = useState('')
    const [projectOwners, setProjectOwners] = useState([])

    const { loading, data } = useQuery(LOAD_TEAM_MEMBERS_QUERY, {
        variables: {
            id: currentTeam,
        },
    })

    useEffect(() => {
        if (project.title && project.owners) {
            setProjectTitle(project.title)
            setProjectOwners(project.owners.map((user) => user.id))
        }
    }, [project])

    useImperativeHandle(ref, () => {
        return {
            state: {
                projectTitle,
                projectOwners,
            },
        }
    })

    let ownerOptions = []

    if (project.team && project.team.id !== currentTeam) {
        ownerOptions = project.owners.map((owner, index) => {
            return {
                key: index,
                value: owner.id,
                text: owner.email,
            }
        })
    } else {
        ownerOptions = data
            ? data.team.members.map((member) => {
                  return {
                      key: member.userId,
                      text: member.user.email,
                      value: member.userId,
                  }
              })
            : []
    }

    return (
        <Fragment>
            <Loader active={loading} />
            <Form.Dropdown
                error={error && error.owners}
                label="Project owners"
                placeholder="Owners"
                fluid
                search
                selection
                multiple
                options={ownerOptions}
                value={projectOwners}
                onChange={(e, { value }) => {
                    setProjectOwners(value)
                }}
            />
            <Form.Input
                error={error && error.title}
                required
                label="Project title"
                placeholder="Title"
                disabled={project.status === 'Completed'}
                value={projectTitle}
                onChange={(e, { value }) => setProjectTitle(value)}
            />
        </Fragment>
    )
}

export default forwardRef(Identity)

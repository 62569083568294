import React, { Component } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import auth0Client from './Auth'

class Callback extends Component {
    static propTypes = {
        history: ReactRouterPropTypes.history.isRequired,
    }

    async componentDidMount() {
        await auth0Client.handleAuthentication()
        window.location.href = '/'
    }

    render() {
        return <p>Loading profile...</p>
    }
}

export default Callback

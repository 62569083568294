import { WebAuth } from 'auth0-js'
import { AUTH0_DATABASE } from '../../constants/auth0'

class Auth {
    constructor() {
        this.auth0 = new WebAuth({
            // the following three lines MUST be updated
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
            audience: `https://${process.env.REACT_APP_AUTH0_API_DOMAIN}/userinfo`,
            clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
            redirectUri: `${process.env.REACT_APP_BASE_URL}/callback`,
            responseType: 'token id_token',
            scope: 'openid email profile',
        })

        this.getProfile = this.getProfile.bind(this)
        this.handleAuthentication = this.handleAuthentication.bind(this)
        this.isAuthenticated = this.isAuthenticated.bind(this)
        this.signIn = this.signIn.bind(this)
        this.signOut = this.signOut.bind(this)
    }

    getProfile() {
        return this.profile
    }

    getIdToken() {
        return this.idToken
    }

    isAuthenticated() {
        return new Date().getTime() < this.expiresAt
    }

    signIn() {
        this.auth0.authorize({}, (err, authResult) => {
            if (err) this.localLogout()
            else {
                this.localLogin(authResult)
                this.accessToken = authResult.accessToken
            }
        })
    }

    customSignIn(database, email, password) {
        return new Promise((resolve, reject) => {
            if (database === AUTH0_DATABASE) {
                this.auth0.login(
                    {
                        realm: database,
                        email,
                        password,
                    },
                    (err, authResult) => {
                        if (err) {
                            return reject(err)
                        } else {
                            this.accessToken = authResult.accessToken
                            resolve()
                        }
                    }
                )
            } else {
                this.auth0.authorize(
                    {
                        connection: database,
                    },
                    (err, authResult) => {
                        if (err) {
                            this.signOut()
                            return reject(err)
                        }
                        this.accessToken = authResult.accessToken
                        resolve()
                    }
                )
            }
        })
    }

    customSignup(database, name, email, password) {
        return new Promise((resolve, reject) => {
            if (database === AUTH0_DATABASE) {
                this.auth0.signup(
                    {
                        connection: database,
                        name,
                        email,
                        password,
                    },
                    (err, authResult) => {
                        if (err) {
                            return reject(err)
                        } else {
                            this.customSignIn(database, email, password)
                            resolve()
                        }
                    }
                )
            } else {
                this.auth0.authorize(
                    {
                        connection: database,
                        mode: 'signUp',
                    },
                    (err, authResult) => {
                        if (err) {
                            this.signOut()
                            return reject(err)
                        }
                        this.accessToken = authResult.accessToken
                        resolve()
                    }
                )
            }
        })
    }

    changePassword(email) {
        this.auth0.changePassword(
            {
                connection: AUTH0_DATABASE,
                email: email,
            },
            function (err, resp) {
                if (err) {
                    console.log(err.message)
                } else {
                    alert(resp)
                }
            }
        )
    }

    handleAuthentication() {
        return new Promise((resolve, reject) => {
            this.auth0.parseHash((err, authResult) => {
                if (err) {
                    alert(err.errorDescription)
                    this.signOut()
                    return reject(err)
                }
                if (!authResult || !authResult.idToken) {
                    return reject(err)
                }
                this.setSession(authResult)
                resolve()
            })
        })
    }

    setSession(authResult) {
        this.idToken = authResult.idToken
        this.profile = authResult.idTokenPayload
        // set the time that the id token will expire at
        this.expiresAt = authResult.idTokenPayload.exp * 1000
    }

    signOut() {
        // clear id token, profile, and expiration
        this.auth0.logout({
            returnTo: process.env.REACT_APP_BASE_URL,
            clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
        })
    }

    silentAuth() {
        return new Promise((resolve, reject) => {
            this.auth0.checkSession({}, (err, authResult) => {
                if (err) return reject(err)
                this.setSession(authResult)
                resolve()
            })
        })
    }
}

const auth0Client = new Auth()

export default auth0Client

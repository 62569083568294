import React, { useContext } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { NavLink, withRouter } from 'react-router-dom'
import auth0Client from '../Auth/Auth'
import TeamSelection from './TeamSelection'
import MenuItem from './MenuItem'
import { AppContext } from '../../Context'

// eslint-disable-next-line react/prop-types
const AppHeader = ({ location, history, showLowCreditMessage }) => {
    const { isSuperUser } = useContext(AppContext)

    const signOut = () => {
        auth0Client.signOut()
        history.push('/login')
    }

    const path = location.pathname.substring(1).split('/')

    const hideHeader =
        path[0] === 'login' ||
        path[0] === 'signup' ||
        path[0] === 'first-time-signup-notice' ||
        (path[0] === 'admin' && path[1] === 'experts' && path[3] === 'review')

    if (hideHeader) return <div></div>

    return (
        <div className="site-navbar py-2">
            <div className="container">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="logo">
                        <div className="site-logo">
                            <NavLink to="/" className="js-logo-clone">
                                <img
                                    src={`${process.env.REACT_APP_AWS_S3_URL}/Dexterlogo.png`}
                                    alt="Logo"
                                />
                            </NavLink>
                        </div>
                    </div>
                    <div className="main-nav d-none d-lg-block">
                        <nav
                            className="site-navigation text-right text-md-center"
                            role="navigation"
                        >
                            <ul className="site-menu js-clone-nav d-none d-lg-block">
                                <MenuItem path="/projects" name="Projects" />
                                <MenuItem path="/advisors" name="Advisors" />
                                <MenuItem path="/payments" name="Payments" />
                                <MenuItem path="/tutorial" name="Dexter 101" />
                                {isSuperUser && (
                                    <MenuItem path="/admin" name="Admin" />
                                )}
                                <li>
                                    <TeamSelection />
                                </li>
                                <li>
                                    <NavLink to="#">
                                        <Button
                                            basic
                                            color="red"
                                            size="small"
                                            onClick={signOut}
                                        >
                                            Sign out
                                        </Button>
                                    </NavLink>
                                </li>
                                <li>
                                    <Icon
                                        name="question circle"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => history.push('/faq')}
                                    />
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="icons">
                        <a
                            href="# "
                            className="site-menu-toggle js-menu-toggle ml-3 d-inline-block d-lg-none"
                        >
                            <span className="icon-menu"></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(AppHeader)

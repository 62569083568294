/* eslint-disable react/prop-types */
import React, { Fragment, useState, useContext } from 'react'
import { Accordion, Icon, Header, Button } from 'semantic-ui-react'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import InviteReminderModal from '../../../Modals/ProjectInvite/InviteReminderModal'
import ScheduleTimeModal from '../../../Modals/Reschedule/ScheduleTimeModal'
import ReviewModal from '../../../Modals/ReviewModal'
import { AppContext } from '../../../../Context'

const ReminderSection = ({ advisor, project, job }) => {
    const { user } = useContext(AppContext)
    const [showReminderModal, setShowReminderModal] = useState(false)
    const [showScheduleModal, setShowScheduleModal] = useState(false)
    const [showReviewModal, setShowReviewModal] = useState(false)
    const [showLogs, setShowLogs] = useState(false)

    return (
        <Fragment>
            <InviteReminderModal
                onShow={showReminderModal}
                onHide={() => setShowReminderModal(false)}
                advisor={advisor}
                project={project}
                job={job}
            />
            <ScheduleTimeModal
                onShow={showScheduleModal}
                onHide={() => setShowScheduleModal(false)}
                job={job}
            />
            <ReviewModal
                onShow={showReviewModal}
                onHide={() => setShowReviewModal(false)}
                project={project}
                job={job}
            />
            {job.meetingAt && (
                <div className="schedule-section">
                    <Header as="h6" style={{ display: 'inline' }}>
                        Scheduled time:{' '}
                    </Header>
                    <a
                        href="# "
                        style={{ display: 'inline' }}
                        onClick={() => setShowScheduleModal(true)}
                    >
                        {momentTZ
                            .tz(job.meetingAt, user.timeZone)
                            .format('llll z')}
                    </a>
                </div>
            )}
            {job.overall_status === 'Completed' && !job.expert_rating && (
                <div className="schedule-section">
                    <Button
                        basic
                        size="mini"
                        onClick={() => setShowReviewModal(true)}
                    >
                        Leave a review
                    </Button>
                </div>
            )}
            {job.invitedAt &&
                job.user.expert.reminder_notify &&
                job.invite_status === 'Invited' &&
                job.overall_status !== 'Completed' &&
                job.response_status === 'Pending' &&
                moment().diff(moment(job.invitedReminderAt), 'days') >= 2 && (
                    <div className="invite-reminder">
                        <i>
                            <Icon name="triangle up" />
                            Invited{' '}
                            {moment().diff(moment(job.invitedAt), 'days')} days
                            ago.{' '}
                        </i>
                        {moment().diff(moment(job.invitedReminderAt), 'days') >=
                            2 && (
                            <span>
                                <i>Click </i>
                                <a
                                    href="# "
                                    onClick={() => setShowReminderModal(true)}
                                >
                                    <i>here</i>
                                </a>
                                <i> to send a reminder email</i>
                            </span>
                        )}
                    </div>
                )}
            <Accordion className="email-logs">
                <Accordion.Title
                    active={showLogs}
                    onClick={() => setShowLogs(!showLogs)}
                    className="email-logs-title"
                >
                    <Icon name="dropdown" />
                    Email logs
                </Accordion.Title>
                <br />
                <br />
                <Accordion.Content
                    active={showLogs}
                    className="email-logs-content"
                >
                    {job.email_logs ? (
                        <ul className="email-logs-list">
                            {job.email_logs.map((log, index) => {
                                return (
                                    <li key={index}>
                                        {`"${log.subject}" sent at ${moment(
                                            log.sent_at
                                        ).format('lll')}`}
                                    </li>
                                )
                            })}
                        </ul>
                    ) : (
                        '(none)'
                    )}
                </Accordion.Content>
            </Accordion>
        </Fragment>
    )
}

export default ReminderSection

import React, { Fragment, useContext } from 'react'
import {
    Grid,
    Popup,
    Statistic,
    Label,
    Button,
    Rating,
    Flag,
} from 'semantic-ui-react'
import countries from '../../constants/countries.json'
import SocialIcon from '../Resources/SocialIcon'
import BlockExpertButton from './BlockExpertButton'
import { AppContext } from '../../Context'

const AdvisorStatus = ({ advisor, onSelect, selectedAdvisors }) => {
    const { isSuperUser } = useContext(AppContext)
    return (
        <Fragment>
            <Grid.Row style={{ paddingBottom: 0 }}>
                <SocialIcon advisor={advisor} />
                <Popup
                    content={advisor.expert.country}
                    trigger={
                        <Flag
                            style={{ marginTop: '7px' }}
                            name={countries[advisor.expert.country]}
                        />
                    }
                />
                <br />
                <span style={{ marginRight: '5px' }}>
                    {advisor.expert.phone}
                </span>
                <span>{advisor.expert.salutation}</span>
            </Grid.Row>
            <Grid.Row style={{ paddingBottom: 0 }} columns={2}>
                <Fragment>
                    <Grid.Column width={10} style={{ paddingLeft: 0 }}>
                        {'Expert Status: '}
                        <Label
                            color={
                                advisor.expert.status === 'Active' &&
                                !advisor.expert.isBlocked
                                    ? 'green'
                                    : 'black'
                            }
                            style={{ marginLeft: '1%', marginRight: '1rem' }}
                        >
                            {advisor.expert.isBlocked
                                ? 'Blocked'
                                : advisor.expert.status}
                        </Label>
                        {isSuperUser && <BlockExpertButton advisor={advisor} />}
                    </Grid.Column>
                    <Grid.Column width={6} floated="right">
                        <span style={{ float: 'right', marginLeft: '5px' }}>
                            {advisor.expert.numRatings}
                        </span>{' '}
                        <Rating
                            icon="star"
                            rating={Math.round(advisor.expert.avgRating) || 0}
                            maxRating={5}
                            style={{ float: 'right', marginTop: '5px' }}
                            disabled
                        />
                    </Grid.Column>
                </Fragment>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column style={{ paddingLeft: 0 }}>
                    Stripe Status:
                    <span
                        style={{
                            color: advisor.expert.stripe_account
                                ? 'green'
                                : 'red',
                            marginLeft: '1%',
                        }}
                    >
                        {advisor.expert.stripe_account
                            ? 'Connected'
                            : 'Not Connected'}
                    </span>
                </Grid.Column>
                {window.location.pathname.includes('/advisors') &&
                window.location.pathname.includes('/projects') ? (
                    <Grid.Column floated="right">
                        <Button
                            color="blue"
                            size="tiny"
                            basic={
                                !selectedAdvisors
                                    .map((advisor) => advisor.id)
                                    .includes(advisor.id)
                            }
                            floated="right"
                            disabled={
                                advisor.expert.status !== 'Active' ||
                                advisor.expert.isBlocked
                            }
                            onClick={() => onSelect(advisor)}
                        >
                            {selectedAdvisors
                                .map((advisor) => advisor.id)
                                .includes(advisor.id)
                                ? 'Selected'
                                : 'Select'}
                        </Button>
                    </Grid.Column>
                ) : null}
            </Grid.Row>
            <Grid.Row>
                <Statistic.Group>
                    <Statistic horizontal>
                        <Statistic.Value>
                            {`$${advisor.expert.hourlyRate}`}
                        </Statistic.Value>
                        <Statistic.Label>/ hr</Statistic.Label>
                    </Statistic>
                    <Statistic horizontal>
                        <Statistic.Value>
                            {advisor.expert.responseRate || 0}%
                        </Statistic.Value>
                        <Statistic.Label>response rate</Statistic.Label>
                    </Statistic>
                    <Statistic horizontal>
                        <Statistic.Value>
                            {advisor.expert.numCompleted || 0}
                        </Statistic.Value>
                        <Statistic.Label>completed engagements</Statistic.Label>
                    </Statistic>
                </Statistic.Group>
            </Grid.Row>
        </Fragment>
    )
}

export default AdvisorStatus

import { gql } from '@apollo/client'

const ME_MODEL = `
    id
    fullName
    firstName
    lastName
    email
    timeZone
    availability {
        dayOfWeek
        startTime
        endTime
    }
    upcomingMeetings {
        id
    }
    membership {
        teamId
        teamName
        role
    }
`

const USER_MODEL = `
    id
    fullName
    firstName
    lastName
    email
    expert {
        id
        title
        organization {
            name
            type
        }
        linkedin_profile
        description
        infoFromLinkedin
        country
        phone
        salutation
        status
        isBlocked
        functions
        medical_specialties
        technology_tags
        internal_tags
        responseRate
        numRatings
        numCompleted
        numPending
        avgRating
        stripe_account
        hourlyRate
    }
`

export const LOAD_ME_QUERY = gql`
    query User {
        me {
            ${ME_MODEL}
        }
    }
`

export const LOAD_USER_QUERY = gql`
  query User($id: String!) {
    user(id: $id) {
      ${USER_MODEL}
    }
  }
`

export const LOAD_USER_BY_EMAIL_QUERY = gql`
    query UserByEmail($email: String!) {
        userByEmail(email: $email) {
            id
        }
    }
`

export const LOAD_EXPERTS_QUERY = gql`
    query Experts(
        $query: String
        $filters: ExpertFilters
        $pagination: Pagination
        $sortBy: ExpertSortBy
    ) {
        experts(
            query: $query
            filters: $filters
            pagination: $pagination
            sortBy: $sortBy
        ) {
            id
            fullName
            salutation
            title
            organization {
                name
                type
            }
            hourlyRate
            profile_picture
            country
            status
            isBlocked
            stripe_account
            functions
            medical_specialties
            technology_tags
            internal_tags
            reminder_notify
            payment_notify
            responseRate
            numCompleted
            numPending
            numRatings
            avgRating
            infoFromLinkedin
            description
            user {
                id
                firstName
                lastName
                fullName
                email
            }
        }
    }
`

export const LOAD_NUM_EXPERTS = gql`
    query Experts(
        $query: String
        $filters: ExpertFilters
        $pagination: Pagination
    ) {
        experts(query: $query, filters: $filters, pagination: $pagination) {
            id
        }
    }
`

export const LOAD_EXPERT_TAGS = gql`
    query User($id: String!) {
        user(id: $id) {
            id
            expert {
                id
                functions
                organization {
                    type
                }
                medical_specialties
                technology_tags
                internal_tags
            }
        }
    }
`

export const UPDATE_EXPERT_INTERNAL_TAGS = gql`
    mutation UpdateExpertInternalTags($id: ID!, $internalTags: [String]) {
        updateExpertInternalTags(id: $id, internalTags: $internalTags) {
            internal_tags
        }
    }
`

export const BLOCK_EXPERT = gql`
    mutation BlockExpert($id: ID!) {
        blockExpert(id: $id) {
            id
            isBlocked
        }
    }
`

export const UNBLOCK_EXPERT = gql`
    mutation UnblockExpert($id: ID!) {
        unblockExpert(id: $id) {
            id
            isBlocked
        }
    }
`

import { gql } from '@apollo/client'

export const SINGLE_PROJECT_MODEL = `
    id
    title
    description
    targetN
    code
    type
    status
    calendly_link
    deletable
    email_templates{
        category
        subject
        content
        last_modify
    }
    owners {
        id
        email
    }
    pending_experts {
        email
        completedAt
    }
    keywords
    referable
    surveyLink
    start_date
    end_date
    jobs {
        user {
            id
        }
    }
    team {
        id
        name
        availableInvites
    }
`

export const LOAD_PROJECTS_QUERY = gql`
    query Projects(
        $query: String
        $filters: ProjectFilters!
        $pagination: Pagination
    ) {
        projects(query: $query, filters: $filters, pagination: $pagination) {
            total
            projects {
                id
                title
                targetN
                code
                type
                owners {
                    id
                    email
                }
                start_date
                end_date
                numAccepted
                numCompleted
                responseRate
            }
        }
    }
`

export const PROJECT_BASIC = gql`
    query Project($id: String!) {
        project(id: $id) {
            id
            status
            type
            service_type
            title
            adminNotes
            owners {
                id
                fullName
                email
            }
            description
            compensationType {
                name
                params
            }
            team {
                id
                name
            }
            qualtricsSurvey {
                surveyId
            }
        }
    }
`

export const LOAD_SINGLE_PROJECT_QUERY = gql`
    query Project($id: String!) {
      project(id: $id) {
          ${SINGLE_PROJECT_MODEL}
      }
    }
`

export const LOAD_PROJECT_JOBS = gql`
    query Project($id: String!) {
        project(id: $id) {
            id
            jobs {
                id
                invite_status
                response_status
                overall_status
                user {
                    id
                    fullName
                    firstName
                    lastName
                    email
                }
                expert {
                    id
                    title
                    fullName
                    salutation
                    country
                    profile_picture
                    organization {
                        name
                    }
                    status
                    stripe_account
                    payment_notify
                }
            }
        }
    }
`

export const LOAD_EXPORT_MANAGE_ADVISORS_DATA = gql`
    query Project($id: String!) {
        project(id: $id) {
            id
            jobs {
                id
                invite_status
                response_status
                overall_status
                completedAt
                user {
                    id
                    email
                    fullName
                    firstName
                    lastName
                }
                expert {
                    id
                    fullName
                    medical_specialties
                    organization {
                        name
                        type
                    }
                    hourlyRate
                }
            }
        }
    }
`

export const LOAD_SINGLE_MANAGED_PROJECT_QUERY = gql`
    query Project($id: String!) {
        project(id: $id) {
            id
            title
            type
            code
            description
            status
            keywords
            deletable
            owners {
                id
                fullName
                email
            }
            compensationType {
                name
                params
            }
            qualificationQuestions {
                id
                question
                questionType
                otherIncluded
                answerChoices
            }
            surveyLink
            qualtricsSurvey {
                surveyId
                status
            }
            start_date
            end_date
            targetN
            jobs {
                invite_status
                response_status
                overall_status
                user {
                    id
                    fullName
                    firstName
                    lastName
                    email
                    expert {
                        id
                        salutation
                        status
                    }
                }
                email_logs {
                    subject
                    sent_at
                }
            }
            team {
                id
                name
                creditBalance
            }
        }
    }
`

export const CREATE_PROJECT_MUTATION = gql`
    mutation createProject($input: ProjectInput!) {
        createProject(input: $input) {
            id
        }
    }
`

export const COMPLETE_PROJECT_MUTATION = gql`
    mutation completeProject($id: ID!) {
        completeProject(id: $id) {
            id
            status
        }
    }
`

export const UPDATE_PROJECT_MUTATION = gql`
    mutation UpdateProject($id: ID!, $input: ProjectInput!) {
        updateProject(id: $id, input: $input) {
            ${SINGLE_PROJECT_MODEL}
        }
    }
`

export const DELETE_PROJECT_MUTATION = gql`
    mutation DeleteProject($id: ID!) {
        deleteProject(id: $id) {
            id
        }
    }
`

export const APPROVE_PROJECT_MUTATION = gql`
    mutation ApproveProject($id: ID!, $compensationType: ProjectCompensationInput!) {
        approveProject(id: $id, compensationType: $compensationType) {
            ${SINGLE_PROJECT_MODEL}
        }
    }
`

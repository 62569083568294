/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Button } from 'semantic-ui-react'
import moment from 'moment'
import PaymentModal from '../../../Modals/Payment/PaymentModal'
import { LOAD_MANAGED_JOB_QUERY } from '../../../../graphql/job'

const PayButtons = ({ job }) => {
    const [showPaymentModal, setShowPaymentModal] = useState(false)

    const { data, loading } = useQuery(LOAD_MANAGED_JOB_QUERY, {
        variables: { id: job.id },
    })

    if (loading)
        return <Button size="mini" floated="right" disabled color="red" />

    const renderPaidText = () => (
        <span style={{ float: 'right' }}>
            {`Paid $ ${data.job.payment.amount} on ${moment(
                data.job.payment.createdAt
            ).format('MM/DD/YY')} via ${data.job.payment.method}`}
        </span>
    )

    const renderPayButton = () => (
        <Button
            size="mini"
            floated="right"
            color="red"
            onClick={() => {
                setShowPaymentModal(true)
            }}
        >
            {data.job.user.expert.stripe_account
                ? 'Pay'
                : 'Record external payment'}
        </Button>
    )

    return (
        <Fragment>
            <PaymentModal
                onShow={showPaymentModal}
                onHide={() => setShowPaymentModal(false)}
                job={data.job}
            />
            {data.job.overall_status === 'Paid'
                ? renderPaidText()
                : renderPayButton()}
        </Fragment>
    )
}

export default PayButtons

import React, { useContext } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { Container, Segment, Button } from 'semantic-ui-react'
import { AppContext } from '../Context'

const FirstTimeSignupPage = () => {
    const history = useHistory()

    const { isDecibio, isSuperUser } = useContext(AppContext)

    if (isDecibio || isSuperUser) {
        return <Redirect to="/" />
    }

    return (
        <Container style={{ marginTop: '10%' }}>
            <Segment>
                <p>
                    You are not authorized to access Dexter Admin portal. If you
                    want to become a client today, please submit a form{' '}
                    <a
                        href="https://dexterexperts.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'black', textDecoration: 'underline' }}
                    >
                        here
                    </a>
                </p>
                <p>
                    Is your team already on Dexter? Please access to Dexter
                    Client portal{' '}
                    <a
                        href={process.env.REACT_APP_CLIENT_BASE_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'black', textDecoration: 'underline' }}
                    >
                        here
                    </a>
                </p>
                <p>
                    More Questions? Contact us at{' '}
                    <a
                        href="mailto:help@dexterexperts.com"
                        style={{ color: 'black', textDecoration: 'underline' }}
                    >
                        help@dexterexperts.com
                    </a>
                </p>
                <Button
                    primary
                    onClick={() => {
                        history.push('/login')
                    }}
                >
                    Back
                </Button>
            </Segment>
        </Container>
    )
}

export default FirstTimeSignupPage

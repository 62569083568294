import React from 'react'
import PropTypes from 'prop-types'
import { Route, useHistory } from 'react-router-dom'
import auth0Client from '../Auth/Auth'
import LoadingSpinner from '../LoadingSpinner'

const propTypes = {
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        .isRequired,
    path: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    checkingSession: PropTypes.bool.isRequired,
}

const SecureRoute = (props) => {
    const { component: Component, path, checkingSession } = props

    const history = useHistory()

    return (
        <Route
            path={path}
            render={() => {
                if (checkingSession) {
                    return (
                        <div>
                            <LoadingSpinner />
                        </div>
                    )
                }

                if (!auth0Client.isAuthenticated()) {
                    history.replace('/login')
                    return <div />
                }

                return <Component />
            }}
        />
    )
}

SecureRoute.propTypes = propTypes

export default SecureRoute

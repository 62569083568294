import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import moment from 'moment'
import { LOAD_TRANSACTIONS_QUERY } from '../../graphql/transaction'
import { Grid, Button, Sidebar, Header, List } from 'semantic-ui-react'
import AddCreditPanel from './AddCreditPanel'
import Heading from './Heading'
import LoadingSpinner from '../LoadingSpinner'
import Pagination from '../Pagination'
import { AppContext } from '../../Context'
import TransferCreditPanel from './TransferCreditPanel'
import ExportTransactionButton from './ExportTransactionButton'

const Credit = ({ team }) => {
    const PER_PAGE = 50
    const { isSuperUser } = useContext(AppContext)

    const [creditAction, setCreditAction] = useState(null)
    const [page, setPage] = useState(1)

    const { loading, data, refetch } = useQuery(LOAD_TRANSACTIONS_QUERY, {
        variables: {
            teamId: team.id,
            pagination: {
                page,
                per_page: PER_PAGE,
            },
        },
        skip: !team.id,
    })

    if (loading) return <LoadingSpinner />

    if (!data) return <div></div>

    const renderSideBar = () => {
        switch (creditAction) {
            case 'Transfer':
                return (
                    <TransferCreditPanel
                        team={team}
                        onTransfer={() => {
                            refetch()
                            setCreditAction(null)
                        }}
                        onCancel={() => setCreditAction(null)}
                    />
                )
            default:
                return (
                    <AddCreditPanel
                        team={team}
                        onAdd={() => {
                            refetch()
                            setCreditAction(null)
                        }}
                        onCancel={() => setCreditAction(null)}
                    />
                )
        }
    }

    return (
        <Sidebar.Pushable style={{ width: '100%' }}>
            <Sidebar
                animation="overlay"
                onHide={() => setCreditAction(null)}
                direction="right"
                visible={creditAction !== null}
                style={{ background: 'white', width: '25%', zIndex: 2999 }}
            >
                {renderSideBar()}
            </Sidebar>
            <Sidebar.Pusher>
                <Heading team={team} totalCredit={team.creditBalance} />
                <Grid style={{ margin: '1% 5% 5% 5%' }}>
                    {isSuperUser && (
                        <Grid.Row>
                            <Grid.Column>
                                <Button
                                    floated="right"
                                    onClick={() => setCreditAction('Transfer')}
                                >
                                    Transfer Credits
                                </Button>
                                <Button
                                    floated="right"
                                    onClick={() => setCreditAction('Add')}
                                >
                                    Add credits
                                </Button>
                                <ExportTransactionButton team={team} />
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <Header>History</Header>
                    </Grid.Row>
                    <Grid.Row>
                        <List>
                            {data.transactions.transactions.length === 0
                                ? 'No transactions found on this page'
                                : data.transactions.transactions.map(
                                      (transaction) => {
                                          const output = `${moment(
                                              transaction.createdAt
                                          ).format('llll')} | ${
                                              transaction.description
                                          }`
                                          return (
                                              <List.Item key={transaction.id}>
                                                  {transaction.job ? (
                                                      <Link
                                                          to={`/projects/${transaction.job.project.id}/manage-advisors`}
                                                          style={{
                                                              color: 'rgb(93, 174, 152)',
                                                          }}
                                                      >
                                                          {output}
                                                      </Link>
                                                  ) : (
                                                      output
                                                  )}
                                              </List.Item>
                                          )
                                      }
                                  )}
                        </List>
                        <Pagination
                            defaultActivePage={page}
                            totalPages={Math.ceil(
                                data.transactions.total / PER_PAGE
                            )}
                            onPageChange={setPage}
                        />
                    </Grid.Row>
                </Grid>
            </Sidebar.Pusher>
        </Sidebar.Pushable>
    )
}

export default Credit

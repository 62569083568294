import React, { useState, useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Popup, Dropdown } from 'semantic-ui-react'
import { LOAD_SINGLE_PROJECT_QUERY } from '../graphql/project'
import SingleProjectMenu from '../components/SingleProject/SingleProjectMenu'
import SingleProjectRoutes from '../components/SingleProject/SingleProjectRoutes'
import ChangeToActiveModal from '../components/Modals/ChangeToActiveModal'
import LoadingSpinner from '../components/LoadingSpinner'
import { AppContext, SingleProjectContext } from '../Context'

import '../components/SingleProject/styles.css'

const SingleProject = () => {
    const history = useHistory()

    const { user, isMember, setCurrentTeam } = useContext(AppContext)

    const [project, setProject] = useState({})
    const [showModal, setShowModal] = useState(false)

    const { id } = useParams()

    const { loading, data, refetch } = useQuery(LOAD_SINGLE_PROJECT_QUERY, {
        variables: { id },
        onCompleted: (data) => {
            setProject(data.project)
            if (isMember) {
                setCurrentTeam(data.project.team.id)
            }
        },
    })

    useEffect(() => {
        if (data && user) {
            setProject(data.project)
            if (isMember) {
                setCurrentTeam(data.project.team.id)
            } else {
                alert('You are not a member of this team')
                history.replace('/')
            }
        }
    }, [data, user, history, isMember, setCurrentTeam])

    if (loading) return <LoadingSpinner />

    const statusOptions = [
        { key: '', text: '', value: '' },
        {
            key: 'Active',
            text: 'Active',
            value: 'Active',
            disabled: project.status === 'Completed',
        },
        {
            key: 'Draft',
            text: 'Draft',
            value: 'Draft',
            disabled:
                project.status === 'Active' || project.status === 'Completed',
        },
    ]

    return (
        <SingleProjectContext.Provider
            value={{
                project,
                setProject,
                refetch,
            }}
        >
            <ChangeToActiveModal
                onShow={showModal}
                onHide={() => setShowModal(false)}
            />
            <div className="project-section">
                <h4 className="title">{project.title}</h4>
                <div className="project-status">
                    <Popup
                        trigger={
                            <Dropdown
                                placeholder="Select status"
                                selection
                                options={statusOptions}
                                value={project.status}
                                className={`${project.status}Color icon tiny fix-arrow`}
                                onChange={(e, { value }) => {
                                    if (value === 'Active') setShowModal(true)
                                }}
                                button
                                style={{
                                    width: '165px',
                                    textAlign: 'center',
                                }}
                            />
                        }
                        disabled={project.status !== 'Draft'}
                        content="Change the project status to Active to start inviting experts and issue payments"
                        style={{ zIndex: '2999' }}
                    />
                </div>
                <SingleProjectMenu />
                <SingleProjectRoutes />
            </div>
        </SingleProjectContext.Provider>
    )
}

export default SingleProject

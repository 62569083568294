import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    Fragment,
} from 'react'
import { useMutation } from '@apollo/client'
import { Button, Input, Dropdown, Checkbox } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap'
import {
    ContentState,
    EditorState,
    convertToRaw,
    convertFromHTML,
} from 'draft-js'
import auth0Client from '../../Auth/Auth'
import { Editor } from 'react-draft-wysiwyg'
import { SEND_EMAIL_MUTATION } from '../../../graphql/email'
import {
    getInvitationEmail,
    getInvitationEmailMarkup,
    getInviteReminderEmail,
    getInvitationReminderEmailMarkup,
    getInvitePendingEmail,
    getInvitationPendingEmailMarkup,
} from '../../Emails'
import {
    getHTMLFromEditorState,
    getEditorStateFromHTML,
} from '../../../utils/helpers'
import { AppContext } from '../../../Context'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './styles.css'

const EmailModal = ({
    onShow,
    onHide,
    title,
    buttonText,
    project = {},
    type,
    onSend,
}) => {
    const { user, isSuperUser } = useContext(AppContext)

    const [email_subject, setEmailSubject] = useState('')
    const [email_content, setEmailContent] = useState('')
    const [editorState, setEditorState] = useState(null)
    const [isCC, setIsCC] = useState(false)

    const [sendEmail] = useMutation(SEND_EMAIL_MUTATION)

    const getEditorState = useCallback(() => {
        let markup = null
        let email_subject = ''

        switch (type) {
            case 'Invite':
                markup = getInvitationEmailMarkup(
                    project.title,
                    getHTMLFromEditorState(project.description)
                )
                email_subject = `Dexter: New Project Invite | ${project.title}`
                break
            case 'InviteReminder':
                markup = getInvitationReminderEmailMarkup(project.title)
                email_subject = `Dexter: Project Invite Reminder | ${project.title}`
                break
            case 'InvitePending':
                markup = getInvitationPendingEmailMarkup(project.title)
                email_subject = `Dexter: Your invite to our expert network`
                break
            default:
                break
        }

        const htmlMarkup = markup.replace(/(?:\r\n|\r|\n)/g, '<br>')

        const blocksFromHTML = convertFromHTML(htmlMarkup)

        const editorState = EditorState.createWithContent(
            ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            )
        )
        setEmailSubject(email_subject)
        setEditorState(editorState)
        setEmailContent(
            getHTMLFromEditorState(
                JSON.stringify(convertToRaw(editorState.getCurrentContent()))
            )
        )
    }, [project, type])

    const getEmailHTML = () => {
        switch (type) {
            case 'Invite':
                return getInvitationEmail(
                    '[Salutation, if available] [Expert full name]',
                    project.title,
                    email_content,
                    project.end_date,
                    isSuperUser ? "[Expert's compensation, if available]" : null
                )
            case 'InviteReminder':
                return getInviteReminderEmail(
                    '[Salutation, if available] [Expert full name]',
                    email_content
                )
            case 'InvitePending':
                return getInvitePendingEmail(email_content)
            default:
                return editorState
                    ? getHTMLFromEditorState(
                          JSON.stringify(
                              convertToRaw(editorState.getCurrentContent())
                          )
                      )
                    : null
        }
    }

    useEffect(() => {
        getEditorState()
    }, [project, getEditorState])

    const onSendEmail = (recipient, sender, cc, text, html, isTest) => {
        const emailInput = {
            to: recipient,
            from: sender,
            subject: email_subject,
            text,
            html,
        }
        if (cc && isCC) {
            if (!isSuperUser) emailInput.cc = cc
            else emailInput.cc = [user.email]
        }
        sendEmail({
            variables: {
                input: emailInput,
            },
            update: (cache) => {
                if (!isTest) onHide()
            },
        })
    }

    const onSendTestEmail = () => {
        const emailInfo = {
            recipient: {
                email: auth0Client.getProfile().email,
            },
            sender: {
                email: auth0Client.getProfile().email,
                name: 'Dexter',
            },
            cc: null,
            topic: `[Sample] ${email_subject}`,
            text: 'Sample New Project Invite',
            html: getEmailHTML(),
        }
        onSendEmail(
            emailInfo.recipient,
            emailInfo.sender,
            emailInfo.cc,
            emailInfo.text,
            emailInfo.html,
            true
        )
        alert('Sent to your email')
    }

    const selectTemplate = (template) => {
        setEmailSubject(template.subject)
        setEditorState(getEditorStateFromHTML(template.content))
        setEmailContent(getHTMLFromEditorState(template.content))
    }

    const emailTemplates =
        project && project.email_templates
            ? [
                  ...project.email_templates
                      .reduce((r, { category, subject, content }) => {
                          r.has(category) ||
                              r.set(category, {
                                  category,
                                  templates: [],
                              })
                          r.get(category).templates.push({ subject, content })
                          return r
                      }, new Map())
                      .values(),
              ]
            : []

    return (
        <Modal
            show={onShow}
            onHide={onHide}
            centered
            backdrop="static"
            size="lg"
            scrollable
            className="invite-modal"
        >
            <Modal.Header closeButton>{title}</Modal.Header>
            <Modal.Body>
                <Dropdown text="Choose an email template" button basic>
                    <Dropdown.Menu>
                        <Dropdown.Header content="Default" />
                        <Dropdown.Menu scrolling>
                            <Dropdown.Item
                                text="Default Template"
                                onClick={getEditorState}
                            />
                        </Dropdown.Menu>
                        {emailTemplates.map((obj, index1) => {
                            return (
                                <Fragment key={index1}>
                                    <Dropdown.Header
                                        content={obj.category}
                                        key={index1}
                                    />
                                    <Dropdown.Menu scrolling>
                                        {obj.templates.map(
                                            (template, index2) => {
                                                return (
                                                    <Dropdown.Item
                                                        key={index2}
                                                        text={template.subject}
                                                        onClick={() =>
                                                            selectTemplate(
                                                                template
                                                            )
                                                        }
                                                    />
                                                )
                                            }
                                        )}
                                    </Dropdown.Menu>
                                </Fragment>
                            )
                        })}
                    </Dropdown.Menu>
                </Dropdown>
                <br />
                <br />
                <Input
                    fluid
                    placeholder="Subject"
                    value={email_subject}
                    onChange={(e, { value }) => setEmailSubject(value)}
                />
                <br />
                <Checkbox
                    label="Receive a copy of the email(s)"
                    checked={isCC}
                    onChange={(e, { checked }) => setIsCC(checked)}
                />
                <br />
                <br />
                <span>
                    <i>
                        The emails to the selected experts will be sent out with
                        their respective inputs for salutation, first name, and
                        last name
                    </i>
                </span>
                <br />
                <span>Your message to the expert: </span>
                <Editor
                    editorState={editorState}
                    onEditorStateChange={(editorState) => {
                        setEditorState(editorState)
                        setEmailContent(
                            getHTMLFromEditorState(
                                JSON.stringify(
                                    convertToRaw(
                                        editorState.getCurrentContent()
                                    )
                                )
                            )
                        )
                    }}
                />
                <br />
                <p>Here is how your email would look: </p>
                <div dangerouslySetInnerHTML={{ __html: getEmailHTML() }}></div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    color="blue"
                    onClick={() =>
                        onSend(email_content, onSendEmail, email_subject)
                    }
                >
                    {buttonText}
                </Button>
                <Button variant="outline-secondary" onClick={onSendTestEmail}>
                    Receive a test email
                </Button>
                <Button variant="outline-secondary" onClick={onHide}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EmailModal

/* eslint-disable react/prop-types */
import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
    Container,
    Header,
    Form,
    Input,
    Button,
    Label,
    List,
    Dropdown,
    Segment,
    ListList,
} from 'semantic-ui-react'
import moment from 'moment'
import Alert from '../../Alert'
import ProjectDeleteButton from './ProjectDeleteButton'
import LoadingSpinner from '../../LoadingSpinner'
import { convertRichTextToPlainText } from '../../../utils//helpers'
import {
    UPDATE_PROJECT_MUTATION,
    APPROVE_PROJECT_MUTATION,
    LOAD_SINGLE_MANAGED_PROJECT_QUERY,
    COMPLETE_PROJECT_MUTATION,
    DELETE_PROJECT_MUTATION,
} from '../../../graphql/project'
import { LOAD_MANAGE_PROJECTS_QUERY } from '../../../graphql/admin'

export const COMPENSATION_OPTIONS = [
    {
        key: 1,
        value: 'Fixed',
        text: 'Use a flat rate for all experts',
    },
    {
        key: 2,
        value: 'MinMax',
        text: "Use the expert's default hourly rate",
    },
]

export const QUALTRICS_SURVEY_URL =
    'https://decibio.qualtrics.com/login?path=%2Fsurvey-builder'

const ProjectControlPanel = ({ projectId, onCancel }) => {
    const [showAlert, setShowAlert] = useState(false)
    const [alertHeader, setAlertHeader] = useState('')
    const [alertBody, setAlertBody] = useState('')
    const [option, setOption] = useState('Fixed')
    const [proRated, setProRated] = useState(0)
    const [minimum, setMinimum] = useState(0)
    const [maximum, setMaximum] = useState(0)
    const [isApprove, setIsApprove] = useState(false)

    const [loadSingleProject, { data, loading, called }] = useLazyQuery(
        LOAD_SINGLE_MANAGED_PROJECT_QUERY
    )

    const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION, {
        refetchQueries: [
            { query: LOAD_MANAGE_PROJECTS_QUERY },
            {
                query: LOAD_SINGLE_MANAGED_PROJECT_QUERY,
                variables: { id: projectId },
            },
        ],
        awaitRefetchQueries: true,
        onError: (error) => {
            setShowAlert(true)
            setAlertHeader('Error')
            setAlertBody(`You can not update this project: ${error.message}`)
        },
    })

    const [deleteProject] = useMutation(DELETE_PROJECT_MUTATION, {
        refetchQueries: [
            { query: LOAD_MANAGE_PROJECTS_QUERY },
            {
                query: LOAD_SINGLE_MANAGED_PROJECT_QUERY,
                variables: { id: projectId },
            },
        ],
        awaitRefetchQueries: true,
        onError: (error) => {
            alert(error.message)
        },
    })

    const [approveProject, { loading: approving }] = useMutation(
        APPROVE_PROJECT_MUTATION,
        {
            refetchQueries: [
                { query: LOAD_MANAGE_PROJECTS_QUERY },
                {
                    query: LOAD_SINGLE_MANAGED_PROJECT_QUERY,
                    variables: { id: projectId },
                },
            ],
            awaitRefetchQueries: true,
            onError: (error) => {
                setShowAlert(true)
                setAlertHeader('Error')
                setAlertBody(
                    `You can not approve this project: ${error.message}`
                )
            },
        }
    )

    const [completeProject] = useMutation(COMPLETE_PROJECT_MUTATION, {
        refetchQueries: [
            { query: LOAD_MANAGE_PROJECTS_QUERY },
            {
                query: LOAD_SINGLE_MANAGED_PROJECT_QUERY,
                variables: { id: projectId },
            },
        ],
        awaitRefetchQueries: true,
        onError: (error) => {
            setShowAlert(true)
            setAlertHeader('Error')
            setAlertBody(`You can not archive this project: ${error.message}`)
        },
    })

    useEffect(() => {
        if (projectId) {
            loadSingleProject({
                variables: { id: projectId },
            })
        }

        if (data) {
            if (data.project.compensationType) {
                setOption(data.project.compensationType.name)
                if (data.project.compensationType.name === 'Fixed') {
                    setProRated(data.project.compensationType.params[0])
                } else {
                    setMinimum(data.project.compensationType.params[0])
                    setMaximum(data.project.compensationType.params[1])
                }
            }
        }
    }, [projectId, data, loadSingleProject])

    const pendingInvitations = data
        ? data.project.jobs.filter(
              (job) =>
                  job.invite_status === 'Not Invited' &&
                  job.response_status === 'Pending' &&
                  job.overall_status === 'Pending Scheduling'
          )
        : []

    const knockoutQuestions =
        data?.project.qualificationQuestions.filter(
            (el) => el.questionType === 'knockout'
        ) ?? []
    const supplementaryQuestions =
        data?.project.qualificationQuestions.filter(
            (el) => el.questionType !== 'knockout'
        ) ?? []
    const onSave = () => {
        updateProject({
            variables: {
                id: projectId,
                input: {
                    compensationType: {
                        name: option,
                        params:
                            option === 'Fixed'
                                ? [parseInt(proRated)]
                                : [parseInt(minimum), parseInt(maximum)],
                    },
                },
            },
        })
    }

    const onDelete = () => {
        deleteProject({
            variables: { id: projectId },
            update: () => onCancel(),
        })
    }

    const onComplete = () => {
        completeProject({
            variables: { id: projectId },
            update: () => onCancel(),
        })
    }

    const onApprove = () => {
        approveProject({
            variables: {
                id: projectId,
                compensationType: {
                    name: option,
                    params:
                        option === 'Fixed'
                            ? [parseInt(proRated)]
                            : [parseInt(minimum), parseInt(maximum)],
                },
            },
            update: () => setIsApprove(false),
        })
    }

    const renderInput = (option) => {
        const inputAttr = {
            type: 'number',
            icon: 'dollar sign',
            iconPosition: 'left',
        }
        switch (option) {
            case 'Fixed':
                return (
                    <Form.Field>
                        <label>
                            Everyone will be offered the same rate of:{' '}
                        </label>
                        <Input
                            {...inputAttr}
                            placeholder="Honorarium per hour (pro-rated)"
                            value={proRated}
                            onChange={(e, { value }) => setProRated(value)}
                        />
                    </Form.Field>
                )
            case 'MinMax':
                return (
                    <Form.Field>
                        <label>
                            Set minimum and maximum boundaries for the
                            honorarium amount
                        </label>
                        <Form.Group widths="equal">
                            <Form.Input
                                {...inputAttr}
                                placeholder="Minimum"
                                value={minimum}
                                onChange={(e, { value }) => setMinimum(value)}
                            />{' '}
                            -
                            <Form.Input
                                {...inputAttr}
                                placeholder="Maximum"
                                value={maximum}
                                onChange={(e, { value }) => setMaximum(value)}
                            />
                        </Form.Group>
                    </Form.Field>
                )
            default:
                return null
        }
    }

    const renderFooter = () => {
        if (data.project.status === 'Draft') {
            if (isApprove) {
                return (
                    <Fragment>
                        <p>
                            This project has {pendingInvitations.length} jobs
                            pending invitation. Review jobs{' '}
                            <Link
                                to={`/projects/${data.project.id}/manage-advisors`}
                                target="_blank"
                                style={{ color: 'green' }}
                            >
                                here
                            </Link>{' '}
                            and send all {pendingInvitations.length} invites by
                            clicking the button below
                        </p>
                        <div>
                            <Button
                                primary
                                loading={approving}
                                disabled={approving}
                                onClick={() => {
                                    onApprove()
                                    onCancel()
                                }}
                            >
                                Approve and Send invites
                            </Button>
                            <ProjectDeleteButton
                                onDelete={onDelete}
                                deletable={data.project.deletable}
                            />
                        </div>
                    </Fragment>
                )
            }
            return (
                <div>
                    <Button
                        primary
                        onClick={() => {
                            setIsApprove(true)
                        }}
                    >
                        Continue
                    </Button>
                    <ProjectDeleteButton
                        onDelete={onDelete}
                        deletable={data.project.deletable}
                    />
                </div>
            )
        } else {
            const { status } = data.project
            return (
                <div>
                    <Button primary onClick={onSave}>
                        Save
                    </Button>
                    {status === 'Active' && (
                        <Button secondary onClick={onComplete}>
                            Complete the project
                        </Button>
                    )}
                    <ProjectDeleteButton
                        onDelete={onDelete}
                        deletable={data.project.deletable}
                    />
                </div>
            )
        }
    }

    if (!called || loading) {
        return <LoadingSpinner />
    }

    return (
        <Container className="side-panel">
            <Alert
                show={showAlert}
                header={alertHeader}
                body={alertBody}
                onClose={() => setShowAlert(false)}
            />
            <Header as="h1">{data.project.team.name}</Header>
            <Header as="h2">{data.project.title}</Header>
            <Label size="large" className={`${data.project.status}Color`}>
                {data.project.status}
            </Label>
            <Form style={{ marginTop: '5%' }}>
                <Form.Field>
                    <label>{`Project type: ${data.project.type}`}</label>
                </Form.Field>
                {data.project.type === 'Survey' && (
                    <Segment>
                        <Header>Survey Information</Header>
                        <Form.Field>
                            <label>
                                {`Survey status: ${data.project.qualtricsSurvey.status}`}
                            </label>
                        </Form.Field>
                        <Form.Field>
                            <label>{`Target N: ${data.project.targetN}`}</label>
                        </Form.Field>
                        <Form.Field>
                            <label>Survey Link</label>
                            <Button
                                primary
                                onClick={() =>
                                    window.open(
                                        `${QUALTRICS_SURVEY_URL}/${data.project.qualtricsSurvey.surveyId}/edit`,
                                        '_blank'
                                    )
                                }
                            >
                                Qualtrics Survey
                            </Button>
                        </Form.Field>
                    </Segment>
                )}
                <Form.Field>
                    <label>
                        {`Case Code: 
                        ${data.project.code || 'Not provided'}`}
                        {data.project.team.name === 'Decibio'
                            ? ' (DBC) - Notify the project owners'
                            : ''}
                    </label>
                </Form.Field>
                <Form.Field>
                    <label>Project Keywords:</label>
                    <List>
                        {data.project.keywords.map((keyword, index) => (
                            <Label basic key={`${keyword}${index}`}>
                                {keyword}
                            </Label>
                        ))}
                    </List>
                </Form.Field>
                <Form.Field>
                    <label>Project owners</label>
                    <List>
                        {data.project.owners.map((owner) => (
                            <Label key={owner.id}>{owner.email}</Label>
                        ))}
                    </List>
                </Form.Field>
                <Form.Field>
                    <label>{`Credit balance: ${data.project.team.creditBalance}`}</label>
                </Form.Field>
                <Form.Field>
                    <label>
                        Duration: {moment(data.project.start_date).format('l')}{' '}
                        - {moment(data.project.end_date).format('l')}
                    </label>
                </Form.Field>
                <Form.Field>
                    <label>Project details</label>
                    <Segment>
                        {convertRichTextToPlainText(data.project.description)}
                    </Segment>
                </Form.Field>
                <Form.Field style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                    <label>Qualification Questions</label>
                    <Segment>
                        {data.project.qualificationQuestions.length !== 0 ? (
                            <>
                                {knockoutQuestions.length > 0 && (
                                    <>
                                        <Header size="small" dividing>
                                            {`Knockouts (N=${knockoutQuestions.length})`}
                                        </Header>
                                        <List ordered>
                                            {knockoutQuestions.map((q) => (
                                                <List.Item key={q.id}>
                                                    {q.question}
                                                </List.Item>
                                            ))}
                                        </List>
                                    </>
                                )}
                                {supplementaryQuestions.length > 0 && (
                                    <>
                                        <Header size="small" dividing>
                                            {`Supplementary Questions (N=${supplementaryQuestions.length})`}
                                        </Header>
                                        <List ordered>
                                            {supplementaryQuestions.map((q) => (
                                                <List.Item key={q.questionId}>
                                                    <List.Header>
                                                        {q.question}
                                                        <Label
                                                            size="small"
                                                            style={{
                                                                marginLeft:
                                                                    '0.5rem',
                                                            }}
                                                        >
                                                            {q.questionType}
                                                        </Label>
                                                    </List.Header>
                                                    {q.answerChoices.length >
                                                        0 && (
                                                        <ListList>
                                                            {q.answerChoices.map(
                                                                (
                                                                    choice,
                                                                    index
                                                                ) => (
                                                                    <List.Item
                                                                        as="li"
                                                                        key={
                                                                            choice +
                                                                            index
                                                                        }
                                                                    >
                                                                        -{' '}
                                                                        {choice}
                                                                    </List.Item>
                                                                )
                                                            )}
                                                            {q.otherIncluded && (
                                                                <List.Item as="li">
                                                                    -
                                                                    Other______
                                                                </List.Item>
                                                            )}
                                                        </ListList>
                                                    )}
                                                </List.Item>
                                            ))}
                                        </List>
                                    </>
                                )}
                            </>
                        ) : (
                            'No qualification question'
                        )}
                    </Segment>
                </Form.Field>
                <Form.Field>
                    <label>
                        How would you like to set an honorarium amount for this
                        project?
                    </label>
                    <Dropdown
                        placeholder="Select options"
                        fluid
                        selection
                        options={COMPENSATION_OPTIONS}
                        value={option}
                        onChange={(e, { value }) => setOption(value)}
                    />
                </Form.Field>
                <p>
                    Note: The following method was used to convert hourly rates
                    in USD to credits. less than $450 = 1 credit, $450-$750 =
                    1.5 credits, greater than $750 = 2 credits
                </p>
                {renderInput(option)}
                {renderFooter()}
            </Form>
        </Container>
    )
}

export default ProjectControlPanel

import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Input, Button } from 'semantic-ui-react'
import auth0Client from '../Auth'

const ResetPasswordModal = ({ onShow, onHide }) => {
    const [email, setEmail] = useState('')

    return (
        <Modal show={onShow} onHide={onHide} centered>
            <Modal.Header closeButton>
                <h3>Reset your password</h3>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Please enter your email address. We will send you an email
                    to reset your password if we are able to locate an account
                    associated with this email address.
                </p>
                <Input
                    fluid
                    placeholder="yours@example.com"
                    value={email}
                    onChange={(e, { value }) => setEmail(value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    primary
                    disabled={email === ''}
                    onClick={() => auth0Client.changePassword(email)}
                >
                    Send Email
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ResetPasswordModal

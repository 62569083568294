import React from 'react'
import { Form } from 'semantic-ui-react'
import AdvisorTags from '../../../Advisors/AdvisorTags'
import JobInformation from './JobInformation'
import Honorarium from './Honorarium'
import ZoomInformation from './ZoomInformation'
import PaymentMethods from './PaymentMethods'
import Description from './Description'
import Message from './Message'
import './style.css'

const PaymentInfo = ({
    job,
    payment,
    updatePayment,
    meetingData,
    setMeetingData,
    setLatestPayment,
}) => {
    const updateMeetingData = (callMinutes, hourlyRate) => {
        setMeetingData({
            callMinutes,
            hourlyRate,
        })
        if (job.project.type === 'Interview') {
            const amount = Number(
                parseFloat((callMinutes * hourlyRate) / 60).toFixed(2)
            )
            updatePayment({
                amount,
                description: `$${amount}; ${callMinutes} minutes at $${hourlyRate} per hour`,
            })
        }
    }

    return (
        <div>
            <Form>
                {job.project.service_type === 'Self-Service' && (
                    <JobInformation job={job} />
                )}
                {job.project.type === 'Interview' && (
                    <ZoomInformation
                        job={job}
                        meetingData={meetingData}
                        updateMeetingData={updateMeetingData}
                    />
                )}
                <Honorarium
                    payment={payment}
                    updatePayment={updatePayment}
                    job={job}
                />
                <Description payment={payment} updatePayment={updatePayment} />
                {!job.user.expert.stripe_account && (
                    <PaymentMethods
                        payment={payment}
                        updatePayment={updatePayment}
                        setLatestPayment={setLatestPayment}
                        job={job}
                    />
                )}
                {job.project.service_type === 'Full-Service' && (
                    <div className="payment-tags-section">
                        <Form.Field>
                            <label>Tags</label>
                        </Form.Field>
                        <div>
                            <AdvisorTags advisor={job.user} />
                        </div>
                    </div>
                )}
            </Form>
            <br />
            <Message
                job={job}
                payment={payment}
                updatePayment={updatePayment}
            />
        </div>
    )
}

export default PaymentInfo

import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { Dropdown } from 'semantic-ui-react'
import { LOAD_TEAMS_NAME_QUERY } from '../../graphql/team'
import { AppContext } from '../../Context'

const TeamSelection = () => {
    const history = useHistory()
    const { user, isSuperUser, currentTeam, setCurrentTeam, setIsDecibio } =
        useContext(AppContext)

    const [currentTeamName, setCurrentTeamName] = useState('')

    useEffect(() => {
        if (user && currentTeam && currentTeamName === '') {
            const thisTeam =
                user.membership.filter((membership) => {
                    return membership.teamId === currentTeam
                })[0] || {}
            setCurrentTeamName(thisTeam.teamName)
        }
    }, [user, currentTeam, currentTeamName])

    const [loadAllTeams, { called, loading, data }] = useLazyQuery(
        LOAD_TEAMS_NAME_QUERY
    )

    if (!user) return <div></div>

    const onSelect = (e, { value, text }) => {
        setIsDecibio(text === 'Decibio')
        setCurrentTeam(value)
        setCurrentTeamName(text)
        history.push('/')
    }

    return (
        <Dropdown
            text={currentTeamName}
            basic
            button
            onClick={() => {
                if (isSuperUser) {
                    loadAllTeams()
                }
            }}
        >
            <Dropdown.Menu>
                {called && loading ? (
                    <Dropdown.Item text="Loading.." disabled />
                ) : isSuperUser && data ? (
                    data.teams.map((team) => {
                        return (
                            <Dropdown.Item
                                key={team.id}
                                value={team.id}
                                text={team.name}
                                onClick={onSelect}
                            />
                        )
                    })
                ) : (
                    user.membership.map((membership) => {
                        return (
                            <Dropdown.Item
                                key={membership.teamId}
                                value={membership.teamId}
                                text={membership.teamName}
                                onClick={onSelect}
                            />
                        )
                    })
                )}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default TeamSelection

import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useParams, Link } from 'react-router-dom'
import {
    Container,
    Segment,
    List,
    Form,
    Header,
    Transition,
    Divider,
} from 'semantic-ui-react'
import moment from 'moment'
import {
    JOB_BASIC,
    UPDATE_JOB_MUTATION,
    COMPLETE_JOB_MUTATION,
} from '../../../graphql/job'
import LoadingSpinner from '../../../components/LoadingSpinner'

/*
    Only allow job status update from:
    -- Pending to Completed
    -- No Show (including all kinds of no shows) to Pending
    -- Cancelled to Pending
    -- Expired to Pending
    -- Declined to Pending
*/
const EDITABLE_STATUS = [
    'Pending Scheduling',
    'Client No Show',
    'Expert No Show',
    'No Show',
    'Cancelled',
    'Expired',
    'Declined',
]

const EditForm = ({ job }) => {
    const [isSaved, setIsSaved] = useState(false)
    const [newStatus, setNewStatus] = useState(null)
    const [offerRate, setOfferRate] = useState(job.offerRate)
    const [adminNotes, setAdminNotes] = useState(job.adminNotes || '')
    const [updateJob, { loading: updating }] = useMutation(
        UPDATE_JOB_MUTATION,
        {
            onCompleted: () => {
                setIsSaved(true)
                setTimeout(() => setIsSaved(false), 3000)
                setNewStatus(null)
            },
        }
    )
    const [completeJob, { loading: completing }] = useMutation(
        COMPLETE_JOB_MUTATION
    )
    const onSubmit = () => {
        const jobInput = {
            hourlyRate: offerRate,
            adminNotes,
        }
        if (newStatus) {
            jobInput.overall_status = newStatus
        }
        if (newStatus === 'Pending Scheduling') {
            jobInput.response_status = 'Pending'
            jobInput.decline_feedback = ''
            jobInput.qualificationResponses = []
        }
        if (newStatus === 'Completed') {
            completeJob({
                variables: {
                    jobId: job.id,
                },
            })
        }
        updateJob({
            variables: {
                userId: job.user.id,
                projectId: job.project.id,
                job: jobInput,
            },
        })
    }
    return (
        <Form onSubmit={onSubmit}>
            <Divider />
            {EDITABLE_STATUS.includes(job.overall_status) && (
                <>
                    <Header as="h5">Change current job status to...</Header>
                    <Form.Radio
                        label="Pending Scheduling"
                        value="Pending Scheduling"
                        checked={newStatus === 'Pending Scheduling'}
                        onChange={() => setNewStatus('Pending Scheduling')}
                    />
                    <Form.Radio
                        label="Completed"
                        value="Completed"
                        checked={newStatus === 'Completed'}
                        onChange={() => setNewStatus('Completed')}
                        disabled={job.overall_status !== 'Pending Scheduling'}
                    />
                </>
            )}
            {job.offerRate && (
                <Form.Input
                    icon="dollar"
                    iconPosition="left"
                    type="number"
                    label="Offer Rate"
                    min={1}
                    value={offerRate}
                    onChange={(e, { value }) => setOfferRate(parseInt(value))}
                />
            )}
            <Form.TextArea
                label="Admin note"
                value={adminNotes}
                onChange={(e, { value }) => setAdminNotes(value)}
            />
            {isSaved && (
                <Transition visible={isSaved} animation="scale" duration={500}>
                    <Header color="green">Successfully saved!</Header>
                </Transition>
            )}
            <Form.Group>
                <Form.Button
                    primary
                    content="Save"
                    loading={updating || completing}
                    disabled={updating || completing}
                />
            </Form.Group>
        </Form>
    )
}

const ManageJob = () => {
    const { id } = useParams()
    const { data, loading } = useQuery(JOB_BASIC, {
        variables: { id },
    })
    if (loading) return <LoadingSpinner />

    const { job } = data

    const INFORMATION = [
        {
            name: 'Job status',
            value: job.overall_status,
        },
        {
            name: 'Team',
            value: job.project.team.name,
        },
        {
            name: 'Project title',
            value: job.project.title,
        },
        {
            name: 'Project type',
            value: job.project.type,
        },
        {
            name: 'Project information',
            value: (
                <Link to={`/admin/projects/${job.project.id}`}>
                    Click to view project page
                </Link>
            ),
        },
        {
            name: 'Meeting time',
            value: job.meetingAt ? moment(job.meetingAt).format('LLL') : null,
        },
        {
            name: 'Meeting link',
            value: job.zoomMeeting.meetingLink,
        },
        {
            name: 'Zoom link',
            value: (
                <a
                    href={`https://us06web.zoom.us/user/${job.zoomMeeting.zoomUserId}/meeting/${job.zoomMeeting.zoomId}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    Click here to access zoom page
                </a>
            ),
        },
        {
            name: 'Expert name',
            value: `${job.user.fullName} (${job.user.email})`,
        },
        {
            name: 'Expert country',
            value: job.expert.country,
        },
        {
            name: 'Expert stripe status',
            value: job.expert.stripe_account ? 'Connected' : 'Not connected',
        },
        {
            name: 'Payment method',
            value: job.payment?.method,
        },
        {
            name: 'Payment paid at',
            value: job.payment?.createdAt
                ? moment(job.payment.createdAt).format('LLL')
                : null,
        },
        {
            name: 'Payment amount',
            value: job.payment?.amount,
        },
        {
            name: 'Payment description',
            value: job.payment?.description,
        },
    ]

    return (
        <Container>
            <Segment>
                <List>
                    {INFORMATION.filter((info) => info.value !== null).map(
                        (info) => (
                            <List.Item key={info.name}>
                                <List.Content>
                                    <List.Header>{info.name}</List.Header>
                                    <List.Description>
                                        {info.value}
                                    </List.Description>
                                </List.Content>
                            </List.Item>
                        )
                    )}
                    {job.bonuses?.length > 0 && (
                        <List.Item>
                            <List.Content>
                                <List.Header>Bonuses information</List.Header>
                                <List.Description>
                                    <List bulleted>
                                        {job.bonuses.map((bonus) => (
                                            <List.Item key={bonus.createdAt}>
                                                {`${moment(
                                                    bonus.createdAt
                                                ).format('LLL')} | ${
                                                    bonus.description
                                                }`}
                                            </List.Item>
                                        ))}
                                    </List>
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    )}
                </List>
                <EditForm job={job} />
            </Segment>
        </Container>
    )
}

export default ManageJob

import React, { Fragment, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import withSizes from 'react-sizes'
import {
    Grid,
    Button,
    Input,
    Form,
    Icon,
    Popup,
    Header,
    Divider,
    List,
    Checkbox,
    Image,
} from 'semantic-ui-react'
import ResetPasswordModal from './ResetPasswordModal'
import SideImage from './SideImage'
import auth0Client from '../Auth'
import { parseDatabase } from '../../../utils/helpers'
import './Auth.css'

const SignIn = ({ history, location, isMobile }) => {
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [name, setName] = useState('')
    const [isAgreeTerms, setIsAgreeTerms] = useState(false)

    const onLogin = async (database) => {
        let realm = parseDatabase(database)
        try {
            await auth0Client.customSignIn(realm, email, password)
        } catch (err) {
            console.log(err)
            alert(err.description)
        }
        setPassword('')
    }

    const onSignup = async (database) => {
        if (password !== confirmPassword) {
            setConfirmPassword('')
            return alert('Confirm password and your password are not matched!')
        }
        let realm = parseDatabase(database)
        try {
            await auth0Client.customSignup(realm, name, email, password)
        } catch (err) {
            console.log(err)
            alert(err.description)
        }
        setPassword('')
        setConfirmPassword('')
    }

    const type = location.pathname === '/login' ? 'LOGIN' : 'SIGNUP'

    return (
        <div className="auth">
            <ResetPasswordModal
                onShow={showResetPasswordModal}
                onHide={() => setShowResetPasswordModal(false)}
            />
            <Grid divided="vertically">
                <Grid.Row columns={isMobile ? 1 : 2}>
                    {!isMobile && (
                        <Grid.Column width={7}>
                            <SideImage />
                        </Grid.Column>
                    )}
                    <Grid.Column width={isMobile ? 16 : 9}>
                        <Grid.Row>
                            <a
                                href="https://www.dexterexperts.com/faq"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="top-link"
                            >
                                <Icon name="question" />
                                {''}
                                Questions about{' '}
                                <span style={{ color: '#43B095' }}>
                                    Dexter
                                </span>{' '}
                                ?
                            </a>
                        </Grid.Row>
                        <Grid className="signin-form">
                            <Grid.Row className="form-header">
                                <Header as="h1" className="main-header">
                                    {type === 'LOGIN'
                                        ? 'Welcome back !'
                                        : 'Sign up'}
                                    <Header.Subheader className="sub-header">
                                        {type === 'LOGIN'
                                            ? "Don't have an account? "
                                            : 'Already a member? '}
                                        <Link
                                            to={
                                                type === 'LOGIN'
                                                    ? 'signup'
                                                    : 'login'
                                            }
                                            style={{ color: 'green' }}
                                        >
                                            {type === 'LOGIN'
                                                ? 'Sign up'
                                                : 'Sign in'}
                                        </Link>{' '}
                                        {isMobile && (
                                            <Popup
                                                trigger={
                                                    <Icon name="info circle" />
                                                }
                                                content={`Dexter provides an exclusive channel for life science 
														professionals and a diverse array of stakeholders playing 
														a role in driving innovation in precision medicine to share 
														their insights with industry leaders`}
                                            />
                                        )}
                                    </Header.Subheader>
                                </Header>
                            </Grid.Row>
                            {type === 'LOGIN' && (
                                <Fragment>
                                    <Grid.Row className="form-social-login">
                                        <Button
                                            basic
                                            onClick={() =>
                                                type === 'LOGIN'
                                                    ? onLogin('google')
                                                    : onSignup('google')
                                            }
                                        >
                                            <Icon name="google" color="red" />{' '}
                                            Sign{' '}
                                            {type === 'LOGIN' ? 'in' : 'up'}{' '}
                                            with Google
                                        </Button>
                                        <Button
                                            basic
                                            onClick={() =>
                                                type === 'LOGIN'
                                                    ? onLogin('linkedin')
                                                    : onSignup('linkedin')
                                            }
                                        >
                                            <Image
                                                src="/logos/lk_icon.png"
                                                className="lk-icon"
                                            />
                                            Sign{' '}
                                            {type === 'LOGIN' ? 'in' : 'up'}{' '}
                                            with Linkedin
                                        </Button>
                                    </Grid.Row>
                                    <Divider horizontal>Or</Divider>
                                    <Grid.Row centered>
                                        <span
                                            style={{
                                                color: '#555',
                                                margin: '0 0 0 5%',
                                            }}
                                        >
                                            Use your email{' '}
                                            {type === 'LOGIN'
                                                ? ' to sign in'
                                                : ' for registration'}
                                        </span>
                                    </Grid.Row>
                                </Fragment>
                            )}
                            <Form style={{ width: '100%', marginTop: '3%' }}>
                                <Form.Field>
                                    <label>Email</label>
                                    <Input
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e, { value }) =>
                                            setEmail(value)
                                        }
                                    />
                                </Form.Field>
                                {type === 'SIGNUP' && (
                                    <Form.Field>
                                        <label>Name</label>
                                        <Input
                                            placeholder="Full name"
                                            value={name}
                                            onChange={(e, { value }) =>
                                                setName(value)
                                            }
                                        />
                                    </Form.Field>
                                )}
                                <Form.Field>
                                    <label>Password</label>
                                    <Input
                                        placeholder={
                                            type === 'SIGNUP'
                                                ? 'Create password'
                                                : 'password'
                                        }
                                        type="password"
                                        value={password}
                                        onChange={(e, { value }) =>
                                            setPassword(value)
                                        }
                                    />
                                </Form.Field>
                                {type === 'SIGNUP' && (
                                    <Form.Field>
                                        <label>Confirm password</label>
                                        <Input
                                            placeholder="Confirm password"
                                            type="password"
                                            value={confirmPassword}
                                            onChange={(e, { value }) =>
                                                setConfirmPassword(value)
                                            }
                                        />
                                    </Form.Field>
                                )}
                                {type === 'LOGIN' && (
                                    <span
                                        className="forget-pw"
                                        onClick={() =>
                                            setShowResetPasswordModal(true)
                                        }
                                    >
                                        {"Don't remember your password ?"}
                                    </span>
                                )}
                                <br />
                                <br />
                                {type === 'SIGNUP' && (
                                    <Form.Field>
                                        <Checkbox
                                            label={{
                                                children: (
                                                    <span>
                                                        Creating an account
                                                        means you accept our{' '}
                                                        <a
                                                            href="https://dexterexperts.com/terms-of-service"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="dark-green"
                                                        >
                                                            Terms of Service
                                                        </a>
                                                        ,{' '}
                                                        <a
                                                            href="https://dexterexperts.com/user-agreements"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="dark-green"
                                                        >
                                                            User Agreement
                                                        </a>
                                                        , and{' '}
                                                        <a
                                                            href="https://dexterexperts.com/privacy-policy"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="dark-green"
                                                        >
                                                            Privacy Policy
                                                        </a>
                                                    </span>
                                                ),
                                            }}
                                            checked={isAgreeTerms}
                                            onChange={(e, { checked }) =>
                                                setIsAgreeTerms(checked)
                                            }
                                        />
                                    </Form.Field>
                                )}
                                <Button
                                    positive
                                    disabled={
                                        email === '' ||
                                        password === '' ||
                                        (type === 'SIGNUP' && !isAgreeTerms)
                                    }
                                    className="signin-btn"
                                    onClick={() =>
                                        type === 'LOGIN'
                                            ? onLogin()
                                            : onSignup()
                                    }
                                >
                                    <span>
                                        {type === 'LOGIN'
                                            ? 'Sign in'
                                            : 'Create Account'}
                                    </span>
                                </Button>
                            </Form>
                            <Grid.Row className="form-footer">
                                <List>
                                    <List.Item>
                                        <Icon
                                            name="connectdevelop"
                                            className="dark-green"
                                        />{' '}
                                        Connect with industry innovators
                                    </List.Item>
                                    <List.Item>
                                        <Icon
                                            name="gift"
                                            className="dark-green"
                                        />{' '}
                                        Get invited to projects that match your
                                        expertise
                                    </List.Item>
                                    <List.Item>
                                        <Icon
                                            name="target"
                                            className="dark-green"
                                        />{' '}
                                        Track your payment in real time
                                    </List.Item>
                                </List>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}

const mapSizesToProps = ({ width }) => ({
    isMobile: width < 1200,
})

export default withRouter(withSizes(mapSizesToProps)(SignIn))

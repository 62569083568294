import React, { useState, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { Card, Grid, Radio, Loader } from 'semantic-ui-react'
import ProjectCard from './ProjectCard'
import Pagination from '../Pagination'
import { LOAD_PROJECTS_QUERY } from '../../graphql/project'
import { AppContext, ProjectContext } from '../../Context'

const ProjectList = ({ category }) => {
    const PER_PAGE = 5

    const { currentTeam } = useContext(AppContext)
    const { query, myProjectsOnly, setMyProjectsOnly } =
        useContext(ProjectContext)

    const [currentPage, setCurrentPage] = useState(1)

    const { data, loading, refetch } = useQuery(LOAD_PROJECTS_QUERY, {
        skip: !currentTeam,
        variables: {
            query,
            filters: {
                status: category,
                team: currentTeam,
                service_type: 'Full-Service',
                myProjectsOnly,
            },
            pagination: {
                page: currentPage,
                per_page: PER_PAGE,
            },
        },
    })

    const projects = data ? data.projects.projects : []

    return (
        <div className="active-page">
            <Radio
                toggle
                checked={myProjectsOnly}
                label="Show my projects only"
                style={{ float: 'left' }}
                onChange={(e, { checked }) => {
                    setMyProjectsOnly(checked)
                }}
            />
            <Loader active={loading} />
            {!loading && (
                <Card.Group>
                    <Grid stackable columns={1}>
                        {projects.length === 0 ? (
                            <span>No {category} projects.</span>
                        ) : (
                            projects.map((project, index) => {
                                return (
                                    <ProjectCard
                                        key={index}
                                        project={project}
                                        refetch={refetch}
                                    />
                                )
                            })
                        )}
                    </Grid>
                </Card.Group>
            )}
            <br />
            {!loading && projects.length !== 0 && (
                <Pagination
                    defaultActivePage={currentPage}
                    totalPages={Math.ceil(data.projects.total / PER_PAGE)}
                    onPageChange={setCurrentPage}
                />
            )}
        </div>
    )
}

export default ProjectList

import React from 'react'
import { Header, List } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap'

const PaymentConfirm = ({ job, payment }) => (
    <Modal.Body>
        <Header as="h6">To be processed:</Header>
        <List>
            {(payment.credits || payment.credits === 0) && (
                <List.Item>
                    {`- Deduct ${payment.credits} credit(s) from ${job.project.team.name}'s account`}
                </List.Item>
            )}
            <List.Item>
                {`${
                    payment.method === 'Stripe'
                        ? `- Pay ${job.user.fullName || job.user.email} $${
                              payment.amount
                          }`
                        : `- Record an external payment of $${
                              payment.amount
                          } to ${job.user.fullName || job.user.email}`
                } with the payment method, ${payment.method}`}
            </List.Item>
        </List>
    </Modal.Body>
)

export default PaymentConfirm

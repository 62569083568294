import React, { useState, useEffect } from 'react'
import moment from 'moment'
import {
    Form,
    Header,
    Table,
    Checkbox,
    Popup,
    Icon,
    Label,
    Divider,
} from 'semantic-ui-react'

const findParticipantFromUser = (expert, participants) => {
    const { firstName, lastName } = expert
    let firstNameRegex = new RegExp(firstName.replace(/\s/g, '\\s?'), 'i')
    let lastNameRegex = new RegExp(lastName.replace(/\s/g, '\\s?'), 'i')
    let initials = new RegExp(
        `${firstName.charAt(0)}${lastName.charAt(0)}`,
        'i'
    )
    return participants.find(
        (participant) =>
            firstNameRegex.test(participant.name.replace(/\s/g, ' ')) ||
            lastNameRegex.test(participant.name.replace(/\s/g, ' ')) ||
            initials.test(participant.name.replace(/\s/g, ' '))
    )?.name
}

const getMeetingTime = (selectedParticipants, participants, meetingAt) => {
    const expertLogs = participants.filter((p) =>
        selectedParticipants.includes(p.name)
    )
    let totalMeetingTime = 0
    let mergedIntervals = []
    if (expertLogs.length > 0) {
        expertLogs.sort((a, b) => {
            return moment(a.joinedAt).diff(moment(b.joinedAt))
        })

        expertLogs.forEach((participant) => {
            let joinMoment = Math.max(
                moment(participant.joinedAt),
                moment(meetingAt)
            )
            let leaveMoment = moment(participant.leftAt)

            if (
                mergedIntervals.length === 0 ||
                joinMoment > mergedIntervals[mergedIntervals.length - 1].end
            ) {
                mergedIntervals.push({
                    start: joinMoment,
                    end: leaveMoment,
                })
            } else {
                mergedIntervals[mergedIntervals.length - 1].end = moment.max(
                    mergedIntervals[mergedIntervals.length - 1].end,
                    leaveMoment
                )
            }
        })

        const maxNonExpertLeaveMoment = participants
            .filter((p) => !selectedParticipants.includes(p.name))
            .sort((a, b) => moment(b.leftAt).diff(moment(a.leftAt)))

        if (maxNonExpertLeaveMoment.length > 0) {
            const latestNonExpertLeave = moment(
                maxNonExpertLeaveMoment[0].leftAt
            )

            mergedIntervals[mergedIntervals.length - 1].end = moment.min(
                mergedIntervals[mergedIntervals.length - 1].end,
                latestNonExpertLeave
            )
        }
        mergedIntervals.forEach((interval) => {
            let intervalDuration = interval.end.diff(interval.start, 'minutes')
            if (intervalDuration > 0) {
                totalMeetingTime += intervalDuration
            }
        })
    }
    return { totalMeetingTime, effectiveMeetingTimes: mergedIntervals }
}

const MeetingLog = ({
    job,
    selectedParticipants,
    updateSelectedParticipants,
}) => (
    <div>
        <Header as="h6">Meeting Log</Header>
        <div>
            Select the Zoom ID(s) of the expert, {job.user.firstName}{' '}
            {job.user.lastName}
        </div>
        <Table basic="very" compact>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>Zoom Id</Table.HeaderCell>
                    <Table.HeaderCell>Joined</Table.HeaderCell>
                    <Table.HeaderCell>Left</Table.HeaderCell>
                    <Table.HeaderCell>Minutes Since Start</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {job.meetingReport?.participants.map((participant, index) => (
                    <Table.Row key={index}>
                        <Table.Cell collapsing>
                            <Checkbox
                                checked={selectedParticipants.includes(
                                    participant.name
                                )}
                                onChange={(e) =>
                                    updateSelectedParticipants(participant.name)
                                }
                            />
                        </Table.Cell>
                        <Table.Cell>
                            {participant.name}
                            {participant.email ? `(${participant.email})` : ''}
                        </Table.Cell>
                        <Table.Cell>
                            {moment(participant.joinedAt).format('LT')}
                        </Table.Cell>
                        <Table.Cell>
                            {moment(participant.leftAt).format('LT')}
                        </Table.Cell>
                        <Table.Cell>
                            {moment(participant.leftAt).diff(
                                moment.max(
                                    moment(job.meetingAt),
                                    moment(participant.joinedAt)
                                ),
                                'minutes'
                            )}
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    </div>
)

const ZoomInformation = ({ job, meetingData, updateMeetingData }) => {
    const meetingParticipants = job.meetingReport?.participants || []

    const [selectedParticipants, setSelectedParticipants] = useState([
        findParticipantFromUser(job.user, meetingParticipants),
    ])
    const [effectiveMeetingTimes, setEffectiveMeetingTimes] = useState([])

    useEffect(() => {
        if (job.meetingReport.participants) {
            const { effectiveMeetingTimes, totalMeetingTime } = getMeetingTime(
                selectedParticipants,
                job.meetingReport.participants,
                job.meetingAt
            )
            setEffectiveMeetingTimes(effectiveMeetingTimes)
            updateMeetingData(totalMeetingTime, meetingData.hourlyRate)
        }
        // Prevent putting updateMeetingData into dependency because it can cause infinite update
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [job, selectedParticipants])

    const updateSelectedParticipants = (participant) => {
        let newSelectedParticipants = []
        if (selectedParticipants.includes(participant)) {
            newSelectedParticipants = selectedParticipants.filter(
                (p) => p !== participant
            )
        } else {
            newSelectedParticipants = [...selectedParticipants, participant]
        }
        setSelectedParticipants(newSelectedParticipants)
    }

    return (
        <div>
            {job.meetingReport?.status === 'Complete' && (
                <MeetingLog
                    job={job}
                    selectedParticipants={selectedParticipants}
                    updateSelectedParticipants={updateSelectedParticipants}
                />
            )}
            <div>
                {'Effective meeting times are: '}
                <Popup
                    trigger={
                        <Icon
                            name="info"
                            inverted
                            circular
                            size="tiny"
                            color="grey"
                        />
                    }
                    style={{
                        zIndex: 9999,
                    }}
                    content="Effective meeting times are calculated from the later of the expert's
          dial-in time and the meeting start time to
          the earlier of the expert's leave time and
          the leave time of all other parties, while
          ignoring any overlapping periods"
                />
                <br />
                {job.meetingReport.participants ? (
                    <ul>
                        {effectiveMeetingTimes.map((time) => (
                            <li key={time.start}>
                                {moment(time.start).format('LT')}-{' '}
                                {moment(time.end).format('LT')} (
                                {moment(time.end).diff(
                                    moment(time.start),
                                    'minutes'
                                )}
                                {'  '}
                                min. )
                            </li>
                        ))}
                    </ul>
                ) : (
                    ' - No meeting log was found'
                )}
            </div>
            <Header as="h6">
                Payment amount{' '}
                <span>
                    <Popup
                        trigger={
                            <Icon
                                name="info"
                                inverted
                                circular
                                size="tiny"
                                color="grey"
                            />
                        }
                        style={{
                            zIndex: 9999,
                        }}
                        content="Enter the call length and hourly rate to compute the honorarium amount, or simply enter the honorarium amount directly."
                    />
                </span>
            </Header>

            <Form.Group>
                <br />
                <div style={{ marginLeft: 10 }}>Call Length</div>
                <Form.Input
                    labelPosition="right"
                    type="number"
                    min="0"
                    placeholder="Minutes"
                    value={meetingData.callMinutes}
                    onChange={(e, { value }) =>
                        updateMeetingData(
                            parseInt(value),
                            meetingData.hourlyRate
                        )
                    }
                >
                    <input />
                    <Label basic>Minutes</Label>
                </Form.Input>
            </Form.Group>

            <Form.Group>
                <div style={{ marginLeft: 10 }}>Hourly Rate</div>
                <Form.Input
                    labelPosition="left"
                    type="number"
                    min="0"
                    placeholder="Rate"
                    value={meetingData.hourlyRate}
                    onChange={(e, { value }) =>
                        updateMeetingData(
                            meetingData.callMinutes,
                            parseInt(value)
                        )
                    }
                >
                    <Label basic>$</Label>
                    <input />
                </Form.Input>
            </Form.Group>
            <Divider />
        </div>
    )
}

export default ZoomInformation

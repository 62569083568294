import React, { useMemo, useState } from 'react'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { Modal } from 'react-bootstrap'
import { Form, Header, FormInput, Loader } from 'semantic-ui-react'
import {
    LATEST_CHECK_PAYMENT,
    CHECK_PAYMENT_INFO_FIELDS,
} from '../../../graphql/transaction'

const CheckPaymentInput = ({ job, payment, updatePayment }) => {
    const NON_EDITABLE_FIELDS = useMemo(() => ['Timestamp'], [])
    const { loading } = useQuery(CHECK_PAYMENT_INFO_FIELDS, {
        skip: payment.checkPaymentInfo !== null,
        onCompleted: ({ checkPaymentInfoFields }) => {
            const DEFAULT_CHECK_INFO = checkPaymentInfoFields.reduce(
                (obj, key) => {
                    obj[key] = ''
                    return obj
                },
                {}
            )
            updatePayment({
                checkPaymentInfo: {
                    ...DEFAULT_CHECK_INFO,
                    'Vendor First Name': job.user.firstName,
                    'Vendor Last Name': job.user.lastName,
                },
            })
        },
    })
    const updateCheckPaymentInfo = (key, value) => {
        const updatedPaymentInfo = {
            ...payment.checkPaymentInfo,
            [key]: value,
        }
        updatePayment({ checkPaymentInfo: updatedPaymentInfo })
    }
    if (loading) return <Loader active />
    return (
        <div>
            {payment.checkPaymentInfo &&
                Object.keys(payment.checkPaymentInfo)
                    .filter((key) => !NON_EDITABLE_FIELDS.includes(key))
                    .map((key) => (
                        <FormInput
                            key={key}
                            label={key}
                            value={payment.checkPaymentInfo[key]}
                            type="text"
                            onChange={(e, { value }) => {
                                updateCheckPaymentInfo(key, value)
                            }}
                        />
                    ))}
        </div>
    )
}

const CheckPayment = ({ job, payment, updatePayment, latestPayment }) => {
    const NON_EDITABLE_FIELDS = useMemo(() => ['Timestamp'], [])
    const [latestCheckPaymentInfo, setLatestCheckPaymentInfo] = useState(null)
    const { loading } = useQuery(LATEST_CHECK_PAYMENT, {
        variables: { expertId: job.user.id },
        skip:
            latestPayment?.method !== 'Check' ||
            (payment.checkPaymentInfo &&
                Object.values(payment.checkPaymentInfo).some(
                    (el) => el !== ''
                )),
        onCompleted: ({ latestCheckPaymentInfo }) => {
            const parsedPaymentInfo = JSON.parse(latestCheckPaymentInfo)
            setLatestCheckPaymentInfo(parsedPaymentInfo)
            const editableFields = Object.fromEntries(
                Object.entries(parsedPaymentInfo).filter(
                    ([key]) => !NON_EDITABLE_FIELDS.includes(key)
                )
            )
            updatePayment({ checkPaymentInfo: editableFields })
        },
    })

    if (loading) return <Loader active />
    return (
        <Modal.Body>
            <Form>
                <Form.Field>
                    <Header as="h6">Check Payment</Header>
                    <div>
                        {latestCheckPaymentInfo &&
                            `Last payment record was on ${moment(
                                latestCheckPaymentInfo['Timestamp']
                            ).format('MM/DD/YY')} for ${job.user.email}`}
                    </div>
                    <CheckPaymentInput
                        job={job}
                        payment={payment}
                        updatePayment={updatePayment}
                    />
                </Form.Field>
            </Form>
        </Modal.Body>
    )
}

export default CheckPayment

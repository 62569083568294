/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { Modal } from 'react-bootstrap'
import { Button, Icon } from 'semantic-ui-react'
import Alert from '../../Alert'
import ProcessSuccess from './ProcessSuccess'
import Credit from './Credit'
import PaymentInfo from './PaymentInfo'
import Confirm from './Confirm'
import { SEND_EMAIL_MUTATION } from '../../../graphql/email'
import { PAY_EXPERT, DEDUCT_CREDIT } from '../../../graphql/transaction'
import { LOAD_MANAGE_JOBS_QUERY } from '../../../graphql/admin'
import { UPDATE_JOB_MUTATION } from '../../../graphql/job'
import { toCredit } from '../../../utils/helpers'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { lowerCase } from 'lodash'
import CheckPayment from './CheckPayment'
import './style.css'

const PaymentModal = ({ onShow, onHide, job }) => {
    const generateDefaultPayment = (job) => ({
        type: 'Job Payment',
        amount: job.offerRate || job.user.expert.hourlyRate,
        description: `$${
            job.offerRate || job.user.expert.hourlyRate
        } for ${lowerCase(job.project.type)}`,
        method: job.user.expert.stripe_account ? 'Stripe' : null,
        credits: job.offerRate ? toCredit(job.offerRate) : undefined,
        additionalMessage: null,
        checkPaymentInfo: null,
    })

    const generateDefaultMeetingData = (job) => ({
        callMinutes: 60,
        hourlyRate: job.offerRate,
    })
    const [showAlert, setShowAlert] = useState(false)
    const [alertHeader, setAlertHeader] = useState('')
    const [alertBody, setAlertBody] = useState('')

    const [payment, setPayment] = useState(generateDefaultPayment(job))
    const [meetingData, setMeetingData] = useState(
        generateDefaultMeetingData(job)
    )
    const [modalPage, setModalPage] = useState('PAYMENT')
    const [latestPayment, setLatestPayment] = useState(null)

    useEffect(() => {
        setPayment(generateDefaultPayment(job))
        setMeetingData(generateDefaultMeetingData(job))
    }, [job])

    const [payExpert, { loading: paying }] = useMutation(PAY_EXPERT, {
        refetchQueries: [
            {
                query: LOAD_MANAGE_JOBS_QUERY,
            },
        ],
        onError: (error) => {
            setShowAlert(true)
            setAlertHeader('Unsuccessful payment')
            setAlertBody(`
                Failed to process the payment due to the following reason: ${error}. 
                Please try again or contact your site administrator if the problem persists
            `)
        },
    })

    const [deductCredit] = useMutation(DEDUCT_CREDIT, {
        onError: (error) => {
            setShowAlert(true)
            setAlertBody(
                `Failed to process credit deduction due to the following reason: ${error}.`
            )
        },
    })

    const [sendEmail] = useMutation(SEND_EMAIL_MUTATION)
    const [updateJob] = useMutation(UPDATE_JOB_MUTATION)
    const isClient = job.project.service_type === 'Self-Service'
    const closeModal = () => {
        setModalPage('PAYMENT')
        onHide()
    }

    const deductCreditOnly = () => {
        const { additionalMessage, ...rest } = payment
        const paymentInput = {
            ...rest,
            type: 'Credit Deduction',
            method: 'Internal',
        }
        deductCredit({
            variables: { jobId: job.id, payment: paymentInput },
            onCompleted: () => {
                setModalPage('SUCCESS')
            },
        })
    }

    const onPay = () => {
        const { additionalMessage, ...rest } = payment
        const paymentInput = { ...rest }
        if (payment.checkPaymentInfo) {
            paymentInput.checkPaymentInfo = JSON.stringify(
                payment.checkPaymentInfo
            )
        }
        payExpert({
            variables: {
                jobId: job.id,
                payment: paymentInput,
            },

            onCompleted: () => {
                setModalPage('SUCCESS')
                // Record email logs to job & send additional message
                const emailLogs = [
                    {
                        subject: 'Dexter: You just got paid',
                        sent_at: new Date(),
                    },
                ]
                // Send addtional message
                if (additionalMessage) {
                    emailLogs.push({
                        subject: `Re: Your Dexter engagement on ${job.project.title}`,
                        sent_at: new Date(),
                    })
                    const emailInput = {
                        to: {
                            email: job.user.email,
                        },
                        from: {
                            email: 'info@dexterexperts.com',
                            name: 'Dexter Team',
                        },
                        subject: `Re: Your Dexter engagement on ${job.project.title}`,
                        text: 'After payment email',
                        html: additionalMessage,
                    }
                    sendEmail({
                        variables: {
                            input: emailInput,
                        },
                        onCompleted: () => {
                            updateJob({
                                variables: {
                                    userId: job.user.id,
                                    projectId: job.project.id,
                                    job: {
                                        email_logs:
                                            job.email_logs.concat(emailLogs),
                                    },
                                },
                            })
                        },
                    })
                }
            },
        })
    }

    const PAGES = {
        PAYMENT: {
            component: (
                <PaymentInfo
                    job={job}
                    payment={payment}
                    updatePayment={(data) =>
                        setPayment({ ...payment, ...data })
                    }
                    meetingData={meetingData}
                    setMeetingData={setMeetingData}
                    setLatestPayment={setLatestPayment}
                />
            ),
            onNext: () => {
                if (!job.user.expert.stripe_account) {
                    if (!payment.method) {
                        setShowAlert(true)
                        setAlertHeader('Missing required field')
                        setAlertBody('Please select a payment method')
                        return
                    }
                } else {
                    setPayment({ ...payment, method: 'Stripe' })
                }
                if (isClient && payment.method === 'Check') {
                    setModalPage('CHECK')
                } else {
                    setModalPage(isClient ? 'CREDIT' : 'CONFIRM')
                }
            },
            onBack: closeModal,
        },
        CHECK: {
            component: (
                <CheckPayment
                    payment={payment}
                    job={job}
                    updatePayment={(data) =>
                        setPayment({ ...payment, ...data })
                    }
                    latestPayment={latestPayment}
                />
            ),
            onBack: () => setModalPage('PAYMENT'),
            onNext: () => setModalPage(isClient ? 'CREDIT' : 'CONFIRM'),
        },
        CREDIT: {
            component: (
                <Credit
                    job={job}
                    payment={payment}
                    updatePayment={(data) =>
                        setPayment({ ...payment, ...data })
                    }
                    meetingData={meetingData}
                />
            ),
            onNext: () => setModalPage('CONFIRM'),
            onBack: () =>
                payment.method === 'Check'
                    ? setModalPage('CHECK')
                    : setModalPage('PAYMENT'),
        },
        CONFIRM: {
            component: (
                <Confirm
                    job={job}
                    payment={payment}
                    deductCreditOnly={deductCreditOnly}
                />
            ),
            onNext: onPay,
            onBack: () => setModalPage(isClient ? 'CREDIT' : 'PAYMENT'),
        },
        SUCCESS: {
            component: <ProcessSuccess />,
        },
    }

    return (
        <Modal
            show={onShow}
            onHide={closeModal}
            centered
            style={{ zIndex: 9999 }}
        >
            <Alert
                show={showAlert}
                header={alertHeader}
                body={alertBody}
                onClose={() => setShowAlert(false)}
            />
            <Modal.Header closeButton>
                <Modal.Title>
                    {`${job.user.fullName || job.user.email} | ${
                        job.project.title
                    }`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{PAGES[modalPage].component}</Modal.Body>
            {(PAGES[modalPage].onNext || PAGES[modalPage].onBack) && (
                <Modal.Footer>
                    <Button icon basic onClick={PAGES[modalPage].onBack}>
                        <Icon name="angle left" />
                    </Button>
                    <Button
                        loading={paying}
                        disabled={paying}
                        onClick={PAGES[modalPage].onNext}
                    >
                        {modalPage === 'CONFIRM' ? 'Confirm' : 'Next'}
                    </Button>
                </Modal.Footer>
            )}
        </Modal>
    )
}

export default PaymentModal

import { gql } from '@apollo/client'

const MANAGE_PROJECTS_MODEL = `
    id
    title
    owners {
        id
        email
        firstName
        lastName
        fullName
    }
    start_date
    end_date
    status
    team {
        id
        name
    }
`

const MANAGE_JOBS_MODEL = `
    id
    user {
        id
        fullName
        firstName
        lastName
        email
    }
    project {
        id
        type
        title
        team {
            id
            name
        }
    }
    response_status
    overall_status
    invitedReminderAt
    meetingAt
    updatedAt
`

const MANAGE_EXPERTS_MODEL = `
    id
    expert {
        id
        fullName
        created_at
        title
        organization {
            name
            url
        }
    }
    linkedinUrl
    scrapedAt
    status
`

export const LOAD_MANAGE_PROJECTS_QUERY = gql`
    query AdminPortal {
        adminPortal {
            actionableProjects {
                ${MANAGE_PROJECTS_MODEL}
            }
        }
    }
`

export const LOAD_MANAGE_JOBS_QUERY = gql`
    query AdminPortal {
        adminPortal {
            actionableJobs {
                ${MANAGE_JOBS_MODEL}
            }
        }
    }
`

export const LOAD_MANAGE_EXPERTS_QUERY = gql`
    query AdminPortal {
        adminPortal {
            actionableExperts {
                ${MANAGE_EXPERTS_MODEL}
            }
        }
    }
`

import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import {
    ApolloProvider,
    ApolloClient,
    HttpLink,
    InMemoryCache,
    ApolloLink,
} from '@apollo/client'
import Datadog from 'react-datadog'
import * as Sentry from '@sentry/browser'
import auth from './components/Auth/Auth'
import App from './App'

const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_GRAPHQL_BASE_URL}`,
})

const omitDeepArrayWalk = (arr, key) => {
    return arr.map((val) => {
        if (Array.isArray(val)) return omitDeepArrayWalk(val, key)
        else if (typeof val === 'object') return omitDeep(val, key)
        return val
    })
}

const omitDeep = (obj, key) => {
    const keys = Object.keys(obj)
    const newObj = {}
    keys.forEach((i) => {
        if (i !== key) {
            const val = obj[i]
            if (val instanceof Date) newObj[i] = val
            else if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key)
            else if (typeof val === 'object' && val !== null)
                newObj[i] = omitDeep(val, key)
            else newObj[i] = val
        }
    })
    return newObj
}

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => {
        const token = auth.idToken
        //console.log(token)
        return {
            headers: {
                ...headers,
                authorization: token ? `${token}` : '',
            },
        }
    })

    return forward(operation)
})

const cleanTypenameLink = new ApolloLink((operation, forward) => {
    if (operation.variables && !operation.variables.file) {
        // eslint-disable-next-line
        operation.variables = omitDeep(operation.variables, '__typename')
    }

    return forward(operation)
})

const client = new ApolloClient({
    link: ApolloLink.from([authMiddleware, cleanTypenameLink, httpLink]),
    cache: new InMemoryCache({
        typePolicies: {
            Expert: {
                merge: true,
                fields: {
                    organization: { merge: false },
                },
            },
            AdminPortal: {
                merge: true,
                fields: {
                    actionableProjects: { merge: false },
                    actionableJobs: { merge: false },
                    actionableExperts: { merge: false },
                },
            },
        },
    }),
})

Sentry.init({ dsn: process.env.REACT_SENTRY_DSN })

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
    <ApolloProvider client={client}>
        <Router>
            <Datadog
                applicationId={process.env.REACT_APP_DATADOG_APPLICATION_ID}
                clientToken={process.env.REACT_APP_DATADOG_CLIENT_TOKEN}
                service="dexter-admin"
                site="datadoghq.com"
                sessionReplayRecording
            >
                <App />
            </Datadog>
        </Router>
    </ApolloProvider>
)

import React, { Fragment, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { getInvitePendingEmail } from '../../Emails'
import { UPDATE_PROJECT_MUTATION } from '../../../graphql/project'
import auth0Client from '../../Auth/Auth'
import EmailModal from './EmailModal'
import Alert from '../../Alert'
import { SingleProjectContext } from '../../../Context'
import './styles.css'

const InvitePendingModal = ({
    onShow,
    onHide,
    pendingAdvisor,
    completedAt,
}) => {
    const history = useHistory()

    const { project, setProject } = useContext(SingleProjectContext)

    const [showAlert, setShowAlert] = useState(false)
    const [alertHeader, setAlertHeader] = useState('')
    const [alertBody, setAlertBody] = useState('')

    const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION)

    const onSend = (email_content, sendEmail, email_subject) => {
        const isValid = (emailAddr) =>
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(emailAddr)

        if (pendingAdvisor.length === 0 || !isValid(pendingAdvisor)) {
            setShowAlert(true)
            setAlertHeader('Invalid Email')
            setAlertBody('Please enter valid email address')
            return
        }

        const tempList = [...project.pending_experts]
        if (!tempList.map((expert) => expert.email).includes(pendingAdvisor)) {
            tempList.push({ email: pendingAdvisor, completedAt })
            updateProject({
                variables: {
                    id: project.id,
                    input: {
                        pending_experts: tempList,
                    },
                },
                update: (cache, { data: { updateProject } }) => {
                    const emailInfo = {
                        recipient: {
                            email: pendingAdvisor,
                        },
                        sender: {
                            email: auth0Client.getProfile().email,
                            name: 'Dexter',
                        },
                        cc: project.owners.map((owner) => owner.email),
                        text: 'New Project Invite for pending experts',
                        html: getInvitePendingEmail(email_content),
                    }
                    sendEmail(
                        emailInfo.recipient,
                        emailInfo.sender,
                        emailInfo.cc,
                        emailInfo.text,
                        emailInfo.html
                    )
                    setProject(updateProject)
                    history.replace(`/projects/${project.id}/manage-advisors`)
                },
            })
        } else {
            setShowAlert(true)
            setAlertHeader(null)
            setAlertBody('This advisor is already added to the project')
        }
    }

    return (
        <Fragment>
            <Alert
                show={showAlert}
                header={alertHeader}
                body={alertBody}
                onClose={() => setShowAlert(false)}
            />
            <EmailModal
                onShow={onShow}
                onHide={onHide}
                project={project}
                type="InvitePending"
                title={`Invite to this project ?`}
                buttonText={`Add & Send email`}
                onSend={onSend}
            />
        </Fragment>
    )
}

export default InvitePendingModal

import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

const AdminMenu = ({ match, history }) => {
    const [activeItem, setActiveItem] = useState('teams')

    useEffect(() => {
        setActiveItem(match.url.split('/')[2] || 'teams')
    }, [match.url])

    const onNavigate = (e, { name }) => {
        setActiveItem(name)
        history.push(`/admin/${name}`)
    }

    return (
        <Menu tabular>
            <Menu.Item
                name="teams"
                active={activeItem === 'teams'}
                onClick={onNavigate}
            >
                Manage teams
            </Menu.Item>
            <Menu.Item
                name="projects"
                active={activeItem === 'projects'}
                onClick={onNavigate}
            >
                Manage projects
            </Menu.Item>
            <Menu.Item
                name="jobs"
                active={activeItem === 'jobs'}
                onClick={onNavigate}
            >
                Manage jobs
            </Menu.Item>
            <Menu.Item
                name="organizations"
                active={activeItem === 'organizations'}
                onClick={onNavigate}
            >
                Manage organizations
            </Menu.Item>
            <Menu.Item
                name="experts"
                active={activeItem === 'experts'}
                onClick={onNavigate}
            >
                Manage experts
            </Menu.Item>
            <Menu.Item
                name="dashboard"
                active={activeItem === 'dashboard'}
                onClick={onNavigate}
            >
                Dashboard
            </Menu.Item>
        </Menu>
    )
}

export default withRouter(AdminMenu)

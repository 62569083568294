import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { Container, Header, Form, Loader, Button } from 'semantic-ui-react'
import { LOAD_TEAMS_QUERY } from '../../graphql/team'
import { TRANSFER_CREDIT } from '../../graphql/transaction'

const TransferCreditPanel = ({ team, onCancel, onTransfer }) => {
    const [toTeamId, setToTeamId] = useState(null)
    const [credits, setCredits] = useState(0)
    const [description, setDescription] = useState('')
    const [error, setError] = useState(null)
    const { data, loading } = useQuery(LOAD_TEAMS_QUERY)
    const [transferCredit, { loading: transferring }] = useMutation(
        TRANSFER_CREDIT,
        {
            onCompleted: () => {
                onTransfer()
            },
            onError: (error) => setError(error),
        }
    )

    if (loading) return <Loader active />

    const isSameCompany = data.teams.find(
        (t) => t.id === toTeamId && t.company === team.company
    )

    const teamOptions = data.teams
        .filter((t) => t.id !== team.id)
        .map((t) => ({
            key: t.id,
            text: `${t.name} (${t.company})`,
            value: t.id,
        }))
    return (
        <Container className="side-panel">
            <Header size="huge" textAlign="center">
                <Header.Content>{team.name}</Header.Content>
            </Header>
            <Form style={{ marginTop: '5%' }}>
                {error && (
                    <Header color="red" as="h5">
                        {error.message}
                    </Header>
                )}
                <Form.Dropdown
                    label="Transfer to which team"
                    placeholder="Select a team"
                    search
                    selection
                    options={teamOptions}
                    value={toTeamId}
                    onChange={(e, { value }) => setToTeamId(value)}
                />
                <Form.Input
                    label="How many credits?"
                    type="number"
                    value={credits}
                    onChange={(e, { value }) => {
                        let amount = value
                        if (amount !== '') {
                            amount = parseInt(amount, 10)
                        }
                        setCredits(amount)
                    }}
                />
                <Form.Input
                    label="Description"
                    placeholder="Optional"
                    value={description}
                    onChange={(e, { value }) => setDescription(value)}
                />
                {toTeamId && !isSameCompany && (
                    <Header as="h5" color="red">
                        Caution: Transferring to a different company
                    </Header>
                )}
                <div>
                    <Button
                        loading={transferring}
                        disabled={transferring || !toTeamId || !credits}
                        onClick={() =>
                            transferCredit({
                                variables: {
                                    fromTeamId: team.id,
                                    toTeamId,
                                    credits,
                                    description,
                                },
                            })
                        }
                    >
                        Submit
                    </Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </div>
            </Form>
        </Container>
    )
}

export default TransferCreditPanel

import React from 'react'
import { Header, List, Button } from 'semantic-ui-react'
import { Modal } from 'react-bootstrap'

const PaymentConfirm = ({ job, payment, deductCreditOnly }) => {
    const displayCreditDeduction = () => {
        if (job.creditDeduction) {
            return (
                <List.Item>
                    <b>{`A credit deduction of ${-job.creditDeduction
                        .credits} credits for the intended amount of $${
                        job.creditDeduction.amount
                    } has already been made`}</b>
                </List.Item>
            )
        } else if (payment.method !== 'Stripe') {
            return (
                <Button onClick={() => deductCreditOnly()}>
                    Deduct Credit Only
                </Button>
            )
        }
    }

    return (
        <Modal.Body>
            <Header as="h6">To be processed:</Header>
            <List>
                {(payment.credits || payment.credits === 0) && (
                    <List.Item>
                        {`- Deduct ${payment.credits} credit(s) from ${job.project.team.name}'s account`}
                    </List.Item>
                )}
                <List.Item>
                    {`${
                        payment.method === 'Stripe'
                            ? `- Pay ${job.user.fullName || job.user.email} $${
                                  payment.amount
                              }`
                            : `- Record an external payment of $${
                                  payment.amount
                              } to ${job.user.fullName || job.user.email}`
                    } with the payment method, ${payment.method}`}
                </List.Item>
                {job.project.service_type === 'Self-Service' &&
                    displayCreditDeduction()}
            </List>
        </Modal.Body>
    )
}

export default PaymentConfirm

import { createContext } from 'react'

export const AppContext = createContext(null)
export const AdvisorContext = createContext(null)
export const ProjectContext = createContext(null)
export const SingleProjectContext = createContext(null)
export const EmailTemplateContext = createContext(null)
export const TeamManageContext = createContext(null)
export const DashboardContext = createContext(null)
export const ExtractionReviewContext = createContext(null)

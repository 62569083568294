import React from 'react'
import { Image, Header } from 'semantic-ui-react'
import './Auth.css'

const SideImage = () => {
    return (
        <div className="side-image">
            <Image
                size="small"
                src={`${process.env.REACT_APP_AWS_S3_URL}/dexterlogo_trans.png`}
                className="logo"
            />
            <Header as="h2" className="footer-text">
                Share Insights
                <br />
                Advance Precision Medicine
                <br />
                Monetize Your Expertise
            </Header>
            <Header as="h5" className="sub-footer-text">
                Dexter provides an exclusive channel for life science
                professionals and a diverse array of stakeholders playing a role
                in driving innovation in precision medicine to share their
                insights with industry leaders
            </Header>
        </div>
    )
}

export default SideImage
